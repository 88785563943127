import React, { Component } from "react";
import ReactDOM from "react-dom";
import Docreg from "../User/doctorregistration/doctorreg";
import Patientreg from "../User/patientregistration/pacientreg";
import Posreg from "../User/POS/Posreg";
import Opticianreg from "../User/opticianregistration/opticianreg";
import Eyetest from "../EyetestComponent/Eyetest";
import Eyeresult from "../EyetestComponent/Eyetestresult";
import Profile from "../User/userprofile/UserProfile";
import Settings from "../dashboardComponent/Settings";
import Presc from "../Prescription/Prescription";
import Dash from "../dashboardComponent/dashboard2";
import Lang from "../Settings/LangugeMaster";
import Testnmas from "../Settings/TestNameMaster";
import { API_URL } from "../../config";
import BulkImageUpload from "../OpthaiImageUpload/BulkImageUpload";
import UserEmailUpdate from "../Settings/UserEmailupdate";

class Dashboard2 extends Component {
  constructor() {
    super();
    this.state = {
      email: localStorage.getItem("email"),
      utype: localStorage.getItem("type"),
      isExternal: localStorage.getItem("isExternal"),
      mailCount: 0,
    };

    this.docreg = this.docreg.bind(this);
    this.patientreg = this.patientreg.bind(this);
    this.posreg = this.posreg.bind(this);
    this.opticianreg = this.opticianreg.bind(this);
    //this.backofficereg = this.backofficereg.bind(this);
    console.log(this.state);
  }

  submit() {}
  docreg() {
    console.log("hi");
    ReactDOM.render(<Docreg />, document.getElementById("c11"));
  }
  componentDidMount() {
    this.mailcount();
    console.log("mounted");
    let logourl = API_URL + "/users/profile";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        localStorage.setItem("isExternal", json.isExternal);
        this.setState({
          email: localStorage.getItem("email"),
          utype: localStorage.getItem("type"),
          isExternal: json.isExternal,
        });
      })
      .catch((e) => {});
  }
  mailcount() {
    let logourl = API_URL + "/RequestedNewMailListCount";
    const requestOptions = {
      method: "GET",
      headers: {
        //"Content-Type": "application/json",
        //Authorization: localStorage.getItem("token"),
      },
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({
          mailCount: json,
        });
      })
      .catch((e) => {});
  }
  patientreg() {
    console.log("hi");
    ReactDOM.render(<Patientreg />, document.getElementById("c11"));
  }
  opticianreg() {
    console.log("hi");
    ReactDOM.render(<Opticianreg />, document.getElementById("c11"));
  }
  posreg() {
    console.log("hi");
    ReactDOM.render(<Posreg />, document.getElementById("c11"));
  }

  Presc() {
    console.log("hi");
    ReactDOM.render(<Presc />, document.getElementById("c11"));
  }

  eyeresult() {
    console.log("hi");
    ReactDOM.render(<Eyeresult />, document.getElementById("c11"));
  }
  prof() {
    console.log("hi");
    ReactDOM.render(<Profile />, document.getElementById("c11"));
  }
  setting() {
    console.log("hi");
    ReactDOM.render(<Settings />, document.getElementById("c11"));
  }
  dash() {
    console.log("hi");
    ReactDOM.render(<Dash />, document.getElementById("c11"));
  }
  Languge() {
    console.log("hi");
    ReactDOM.render(<Lang />, document.getElementById("c11"));
  }
  Opthai() {
    console.log("hi");
    //ReactDOM.render(<Opthai />, document.getElementById("c11"));
    ReactDOM.render(<BulkImageUpload />, document.getElementById("c11"));
  }
  Testnmas() {
    console.log("hi");
    ReactDOM.render(<Testnmas />, document.getElementById("c11"));
  }
  UserEmail() {
    console.log("hi");
    ReactDOM.render(<UserEmailUpdate />, document.getElementById("c11"));
  }
  render() {
    return (
      <div class="horizontal-menu-wrapper ">
        <div
          class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border"
          role="navigation"
          data-menu="menu-wrapper"
          data-menu-type="floating-nav"
        >
          <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
              <li class="nav-item mr-auto">
                <a
                  class="navbar-brand"
                  href="../../../html/ltr/horizontal-menu-template/index.html"
                >
                  <span class="brand-logo"></span>
                  <h2 class="brand-text mb-0">SymOptics</h2>
                </a>
              </li>
              <li class="nav-item nav-toggle">
                <a
                  class="nav-link modern-nav-toggle pr-0"
                  data-toggle="collapse"
                >
                  <i
                    class="d-block d-xl-none text-primary toggle-icon font-medium-4"
                    data-feather="x"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="shadow-bottom"></div>

          <div
            class="navbar-container main-menu-content"
            data-menu="menu-container"
          >
            <ul
              class="nav navbar-nav"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              <li class="dropdown nav-item" data-menu="dropdown">
                <a
                  class=" nav-link d-flex align-items-center"
                  onClick={this.dash}
                >
                  <i class="fas fa-home fa-fw fa-fs text-secondary"></i>
                  <span data-i18n="Dashboards">
                    {window.$language.Dashboards}
                  </span>
                </a>
              </li>
              <li class="dropdown nav-item" data-menu="dropdown">
                <a
                  class=" nav-link d-flex align-items-center"
                  onClick={this.prof}
                >
                  <i class="fas fa-user fa-fw fa-fs text-secondary"></i>
                  <span data-i18n="Dashboards">{window.$language.Profile}</span>
                </a>
              </li>
              {this.state.utype == "BACKOFFICE" ||
              this.state.utype == "ADMIN" ||
              (this.state.utype == "OPTICIAN" &&
                this.state.isExternal !== null &&
                this.state.isExternal == false) ? (
                <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    class=" nav-link d-flex align-items-center"
                    onClick={this.Opthai}
                  >
                    <i class="fas fa-user fa-fw fa-fs text-secondary"></i>
                    <span data-i18n="Dashboards">
                      {window.$language.MenuBulkImageUpload}
                    </span>
                  </a>
                </li>
              ) : null}
              {/* {this.state.utype == "OPTICIAN" ? (
                <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    target="_blank"
                    class=" nav-link d-flex align-items-center"
                    href="https://share-eu1.hsforms.com/1vKTwk-WaS6CgTxQpA5ZJMQf13gu"
                  >
                    <i class="fa fa-envelope fa-fw fa-fs text-secondary"></i>
                    <span data-i18n="Dashboards">
                      Modification - mail patient erroné
                    </span>
                  </a>
                </li>
              ) : null} */}
              {this.state.utype == "ADMIN" ? (
                <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    class=" nav-link d-flex align-items-center"
                    onClick={this.Languge}
                  >
                    <i class="fas fa-language"></i>
                    <span data-i18n="Dashboards">
                      {window.$language.LanguageMaster}
                    </span>
                  </a>
                </li>
              ) : null}
              {this.state.utype == "ADMIN" ||
              this.state.utype == "BACKOFFICE" ? (
                <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    class=" nav-link d-flex align-items-center"
                    onClick={this.Testnmas}
                  >
                    <i class="far fa-plus-square"></i>
                    <span data-i18n="Dashboards">
                      {window.$language.TestNameMaster}
                    </span>
                  </a>
                </li>
              ) : null}
              {this.state.utype == "ADMIN" ||
              this.state.utype == "BACKOFFICE" ||
              this.state.utype == "OPTICIAN" ? (
                <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    class=" nav-link d-flex align-items-center"
                    onClick={this.UserEmail}
                  >
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span class="pr-1" data-i18n="Dashboards">
                    Modification – Mail patient erroné
                    </span>
                    {/* {this.state.utype == "ADMIN" ||
                    this.state.utype == "BACKOFFICE" ? (
                      <div
                        class={
                          this.state.mailCount == 0
                            ? "badge badge-light-primary"
                            : "badge badge-success"
                        }
                      >
                        {" "}
                        {this.state.mailCount}{" "}
                      </div>
                    ) : null} */}
                  </a>
                </li>
              ) : null} 
               {this.state.isExternal == true ? (
                   <li class="dropdown nav-item" data-menu="dropdown">
                  <a
                    class=" nav-link d-flex align-items-center"
                     href="https://share-eu1.hsforms.com/1AvcfStnBTvqQObQBaADnSAf13gu" target="_blank">
                 <i class="far fa-file-pdf "></i>
                    <span data-i18n="Dashboards">
                     Demande de procuration

                    </span>
                  </a>
                </li> ) : null}
              <li class="dropdown nav-item d-none" data-menu="dropdown">
                <a
                  class="dropdown-toggle nav-link d-flex align-items-center"
                  href="#"
                  data-toggle="dropdown"
                >
                  <i class="fas fa-layer-group"></i>
                  <span data-i18n="User Interface">
                    {window.$language.Quicklinks}
                  </span>
                </a>
                <ul class="dropdown-menu">
                  {/* new  */}
                  {this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <li data-menu="">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        onClick={this.docreg}
                        data-i18n="Edit"
                      >
                        <i class="far fa-caret-square-right"></i>
                        <span data-i18n="Edit">
                          {window.$language.ViewDoctor}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <li data-menu="">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        onClick={this.opticianreg}
                        data-i18n="Edit"
                      >
                        <i class="far fa-caret-square-right"></i>
                        <span data-i18n="Edit">
                          {window.$language.ViewOptician}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <li data-menu="">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        onClick={this.patientreg}
                        data-i18n="Edit"
                      >
                        <i class="far fa-caret-square-right"></i>
                        <span data-i18n="Edit">
                          {window.$language.ViewPatient}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  {this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <li data-menu="">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        onClick={this.posreg}
                        data-i18n="Edit"
                      >
                        <i class="far fa-caret-square-right"></i>
                        <span data-i18n="Edit">
                          {window.$language.ViewPointOfSales}
                        </span>
                      </a>
                    </li>
                  ) : null}
                  <li data-menu="">
                    <a
                      class="dropdown-item d-flex align-items-center"
                      onClick={this.eyeresult}
                      data-i18n="Edit"
                    >
                      <i class="far fa-caret-square-right"></i>
                      <span data-i18n="Edit">
                        {window.$language.ViewResult}
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard2;
