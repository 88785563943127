import React, { Component } from "react";
import ReactDOM from "react-dom";
import { API_URL } from "../../config";
import swal from "sweetalert";
import $ from "jquery";

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
    };
  }
  submit() {
    debugger
    /* Validations */
    var ret = false;
    $("#inputform")
      .find(".form-group, .form-row")
      .find(".form-control")
      .each(function () {
        if ($(this).val() == "") {
          swal({
            title: "OOPS!",
            text:
              $(this).parent().find("label").html() +
              window.$language.cannotbeblank,
            icon: "warning",
          });
          ret = true;
          return false;
        }
      });

    if (ret) return false;
    /* Validations */

    let data1 = {
      email: document.getElementById("email11").value,
    };
    /////////////////
    let logourl = API_URL + "/forgotpassword";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.text())
      .then((text) => {
        if (text === "success") {
          swal({
            title: "Done!",
            text: window.$language.Pleasecheckyourmail,
            icon: "success",
          }).then(() => {
            localStorage.clear();
            window.location.href = "/login";
          });
        } else if (text === "Email id does not exists") {
          swal({
            title: "OOPS!",
            text: window.$language.YouAreNotRegistered,
            icon: "warning",
          });
        } else {
          swal({
            title: "OOPS!",
            text: text,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        //    swal({
        //        title: "OOPS!",
        //        text: "Something went wrong!",
        //        icon: "warning",
        //    });

        console.log(e);
      });
  }

  render() {
    return (
      <div>
        <div className="app-content content  ">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper">
            <div className="content-body">
              <div className="auth-wrapper auth-v2">
                <div className="auth-inner row m-0">
                  <a className="brand-logo" href="javascript:void(0);"></a>

                  <div className="d-none d-lg-flex col-lg-8 align-items-center p-0 border-right bg-white">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-0">
                    <img
                        src="../../../app-assets/images/pages/login-v2v1.png"
                        alt="Login V2"
                        className="imagesize_898"
                      />
                    </div>
                  </div>

                  <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5 bg-light">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                      <img
                        className="img-fluid34"
                        src="../../../app-assets/images/logo/logo.png"
                        alt="Login V2"
                      />

                      <p className="card-text mb-2"></p>

                      <h4 class="card-title mb-1">
                        {window.$language.ForgotPassword} ? 🔒
                      </h4>

                      <form class="auth-forgot-password-form mt-2">
                        <div class="form-group">
                          <label for="forgot-password-email" class="form-label">
                            {window.$language.Email}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="email11"
                            name="forgot-password-email"
                            placeholder="john@example.com"
                            aria-describedby="forgot-password-email"
                            tabindex="1"
                            autofocus
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => this.submit()}
                          class="btn btn-primary btn-block btn_color454"
                          tabindex="2"
                        >
                          {window.$language.Resetpassword}
                        </button>
                      </form>

                      <p class="text-center mt-2">
                        <a href="/login">
                          {" "}
                          <i data-feather="chevron-left"></i>{" "}
                          {window.$language.Backtologin}?
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Settings;
