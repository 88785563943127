import React, { Component } from "react";
import ReactDOM from "react-dom";
import Posreg from "./Posreg";
import { API_URL } from "../../../config";

class Posdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      utype: localStorage.getItem("type"),
    };

    console.log(this.props);
  }
  submit() {}
  posreg() {
    console.log("hi");
    ReactDOM.render(<Posreg />, document.getElementById("c11"));
  }

  render() {
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <section class="app-user-edit col-9 pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="media mb-2">
                            <img
                              src={
                                this.props.usr.profileImagePath == ""
                                  ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                  : API_URL +
                                    "/" +
                                    this.props.usr.profileImagePath
                              }
                              alt="users avatar"
                              class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                              height="90"
                              width="90"
                            />
                            <div class="media-body mt-50">
                              <h4>
                                {this.props.usr.name +
                                  "  " +
                                  this.props.usr.lastName}
                              </h4>
                            </div>
                          </div>

                          <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.Name}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    defaultValue={this.props.usr.name}
                                    readOnly
                                    name="name"
                                    id="name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.Email}
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    defaultValue={this.props.usr.email}
                                    readOnly
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                            </div>
                          </form>
                          <form class="form-validate">
                            <div class="row mt-1">
                              <div class="col-12">
                                <h4 class="mb-1">
                                  <i
                                    data-feather="user"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    {window.$language.PersonalInformation}
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.UserType}
                                  </label>
                                  <select id="languages" class="form-control">
                                    <option value="Russian" readOnly selected>
                                      POS
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="fp-default">
                                    {window.$language.DateofBirth}
                                  </label>
                                  <input
                                    id="fp-default"
                                    type="text"
                                    defaultValue={this.props.usr.dateOfBirth}
                                    readOnly
                                    class="form-control birthdate-picker"
                                    name="dob"
                                    placeholder="01 Jan 2000"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="mobile">
                                    {window.$language.Phone}
                                  </label>
                                  <input
                                    id="mobile"
                                    defaultValue={this.props.usr.mobile}
                                    readOnly
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.Languages}
                                  </label>
                                  <select
                                    id="languages"
                                    readOnly
                                    class="form-control"
                                  >
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French" selected>
                                      French
                                    </option>
                                    <option value="Russian">Russian</option>
                                    <option value="German">German</option>
                                    <option value="Arabic">Arabic</option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                {/* <div class="form-group">
                                                        <label class="d-block mb-1">Gender</label>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="male" name="gender" class="custom-control-input" />
                                                            <label class="custom-control-label" for="male">Male</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="female" name="gender" class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="female">Female</label>
                                                        </div>
                                                    </div> */}
                              </div>

                              {/* <div class="col-12">
                                                    <h4 class="mb-1 mt-2">
                                                        <i data-feather="map-pin" class="font-medium-4 mr-25"></i>
                                                        <span class="align-middle">Address</span>
                                                    </h4>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label for="address-1">Address</label>
                                                        <input id="address-1" defaultValue={this.props.usr.doctorAddressOfTheHealthCenter} readOnly type="text" class="form-control" value="" name="address" />
                                                    </div>
                                                </div> */}

                              {/* <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label for="postcode">Postcode</label>
                                                        <input id="postcode" type="text" class="form-control" placeholder="" name="zip" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label for="city">City</label>
                                                        <input id="city" type="text" class="form-control" value="New York" name="city" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label for="state">State</label>
                                                        <input id="state" type="text" class="form-control" name="state" placeholder="" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label for="country">Country</label>
                                                        <input id="country" type="text" class="form-control" name="country" placeholder="" />
                                                    </div>
                                                </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.posreg}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Posdetails;
