import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { API_URL } from "./config";
window.$language = {};
console.log = () => {};
let selLanguage = localStorage.getItem("sel-language");
if (selLanguage === null || selLanguage === undefined || selLanguage === "")
  selLanguage = "fr";

window.$SelectedLanguage = selLanguage;

fetch(API_URL + "/loadLanguageJSON/" + selLanguage, {
  method: "GET",
  headers: { "Content-Type": "application/json" },
})
  .then((res) => res.json())
  .then((json) => {
    window.$language = json;
    console.log(window.$language);

    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
