import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import DataTable from "../datatables";
import { API_URL } from "../../config";
import swal from "sweetalert";

class LangugeMaster extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      languge:[]

    };
  }
  submit() {}
  componentDidMount() {
    var token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    let logourl = API_URL + "/languagesOpthai";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({ languge: json });
      });
  }
  render() {
    return (
      <>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section id="basic-datatable">
              <div class="row">
                <div class="col">
                  <div class="card px-2 pb-4">
                    <div class="card-header border-bottom mr-1">
                      <div class="head-label">
                        <h4 class="mb-0">{window.$language.Language}</h4>
                      </div>
                      <div class="dt-action-buttons text-right">
                        <div class="dt-buttons d-inline-flex">
                          {/* <button class="btn btn-primary mr-1" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#modals-slide-in">
                                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus mr-50 font-small-4"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>Add Language</span></button>  */}
                        </div>
                      </div>
                    </div>

                    <hr class="my-0" />
                    <div class="d-flex justify-content-between align-items-center mx-0 row"></div>

                    <table class="datatables-basic table mt-1 shadow  table-hover">
                      <thead>
                        <tr>
                        <th>Language Id</th>
                          <th>{window.$language.LANGUAGENAME}</th>
                          <th>OphtAI Language Code</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.languge.map((det, index) => (
                        <tr>
                          <td>{det.languageId}</td>
                          <td>{det.languageName}</td>
                          <td>{det.ophtAILanguageCode}</td>
                        </tr>))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm w-25">
                  <form class="add-new-record modal-content pt-0">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                        New Record
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1">
                      <div class="form-row mb-1">
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.language}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="fname"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1"
                        onClick={() => this.submit()}
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
export default LangugeMaster;
