import React, { Component } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { API_URL } from "../../config";
import $ from "jquery";
import Productdetails from "./productdetails";
import SymLoader from "../SymLoader/SymLoader";
class Product extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      prodarr: [{}],
      count: 1,
      idp: 0,
      isLoading:false
    };
  }
  delete(email) {
    swal({
      title: window.$language.Areyousure,
      text: window.$language.AreyousuretoDelete,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/Products/delete";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            productCode: email,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: window.$language.DeletedSuccessfully,
                icon: "success",
              });
              this.componentDidMount();
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  componentDidMount() {
    this.getprodinfo("");
  }

  uploadPic(event, p) {
    //alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("ProductCode", p.productCode);
      formData.append("ProductImage", event.target.files[0]);
      let logourl = API_URL + "/Products/image/change";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.getprodinfo("");
      });
    }
  }

  getprodinfo(code) {
    this.setState({isLoading:true})
    let logourl = API_URL + "/Products/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ProductCode: code,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ prodarr: json });
        this.setState({isLoading:false})
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }

  submit() {
    /* Validations */
    var ret = false;
    $("#inputform")
      .find(".form-group, .form-row")
      .find(".form-control")
      .each(function () {
        if ($(this).val() == "") {
          swal({
            title: "OOPS!",
            text:
              $(this).parent().find("label").html() +
              window.$language.cannotbeblank,
            icon: "warning",
          });
          ret = true;
          return false;
        }
      });

    if (ret) return false;
    /* Validations */

    let data1 = {
      productCode: this.state.idp,
      Productname: document.getElementById("Productname").value,
      ProductType: document.getElementById("ProductType").value,
      ProductSubType: document.getElementById("ProductSubType").value,
      CorrectionRange: document.getElementById("CorrectionRange").value,
      BlueFilter: document.getElementById("BlueFilter").value,
      TintType: document.getElementById("TintType").value,
      TreatmentType: document.getElementById("TreatmentType").value,
    };
    /////////////////
    let logourl = API_URL + "/Products/add";
    let msg = "";
    if (this.state.count == 1) {
      logourl = API_URL + "/Products/add";
      msg = window.$language.AddedSuccessfully;
    } else {
      logourl = API_URL + "/Products/modify";
      msg = window.$language.Editedsuccessfully;
    }
    /////////////////////
    // let logourl = API_URL + "/Products/add";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //this.setState({usrdet:json})
        // alert(json)
        swal({
          title: "Done!",
          text: msg,
          icon: "success",
        });
        document.getElementById("close").click();
        this.componentDidMount();
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
    //////
  }
  Productdetails(prd) {
    console.log("hi");
    ReactDOM.render(
      <Productdetails prd={prd} />,
      document.getElementById("c11")
    );
  }
  addnew() {
    this.setState({
      count: 1,
    });
    this.reset();
  }
  edit(usr) {
    this.setState({
      count: 2,
      idp: usr.productCode,
    });
    this.reset();
    document.getElementById("Productname").value = usr.productname;
    document.getElementById("ProductType").value = usr.productType;
    document.getElementById("ProductSubType").value = usr.productSubType;
    document.getElementById("CorrectionRange").value = usr.correctionRange;
    document.getElementById("BlueFilter").value = usr.blueFilter;
    document.getElementById("TintType").value = usr.tintType;
    document.getElementById("TreatmentType").value = usr.treatmentType;
  }
  reset() {
    document.getElementById("Productname").value = "";
    document.getElementById("ProductType").value = "";
    document.getElementById("ProductSubType").value = "";
    document.getElementById("CorrectionRange").value = "";
    document.getElementById("BlueFilter").value = "";
    document.getElementById("TintType").value = "";
    document.getElementById("TreatmentType").value = "";
  }
  render() {
    return (
      <>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section id="basic-datatable">
            {this.state.isLoading ? <SymLoader /> : ""}
              <div class="row">
                <div class="col">
                  <div class="card px-2 pb-4">
                    <div class="card-header border-bottom mr-1">
                      <div class="head-label">
                        <h4 class="mb-0">{window.$language.ViewProducts}</h4>
                      </div>
                      <div class="dt-action-buttons text-right">
                        <div class="dt-buttons d-inline-flex">
                          {/*<div class="btn-group mr-1">
                                                        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {window.$language.Export}
                                                        </button>
                                                        <div class="dropdown-menu ">
                                                        <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-print mr-50"></i>{window.$language.Print}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-csv mr-50"></i>{window.$language.CSV}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-excel mr-50"></i>{window.$language.Excel}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-pdf mr-50"></i>{window.$language.PDF}</a>
                                                        </div>
        </div>*/}
                          <button
                            onClick={() => this.addnew()}
                            class="btn btn-primary mr-1"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            type="button"
                            data-toggle="modal"
                            data-target="#modals-slide-in"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus mr-50 font-small-4"
                              >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </svg>
                              {window.$language.AddNewProduct}
                            </span>
                          </button>{" "}
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mx-0 row">
                      <div class="col-sm-12 col-md-6">
                        {/*<div class="dataTables_length" id="DataTables_Table_0_length"><label>Show 
                                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select form-control"><option value="7">7</option>
                                        <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="75">75</option><option value="100">100</option></select></label>
        </div>*/}
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div
                          id="DataTables_Table_0_filter"
                          class="dataTables_filter pt-1"
                        >
                          <label class="w-100">
                            <input
                              onChange={(data) =>
                                this.getprodinfo(data.target.value)
                              }
                              type="search"
                              class="form-control rounded-lg shadow-sm"
                              placeholder="Rechercher une information"
                              aria-controls="DataTables_Table_0"
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <table class="table-responsive datatables-basic table mt-1 shadow  table-hover">
                      <thead>
                        <tr>
                          <th>{window.$language.PRODUCTID}</th>
                          <th>{window.$language.PRODUCTIMAGE}</th>
                          <th>{window.$language.PRODUCTNAME}</th>
                          <th>{window.$language.PRODUCTTYPE}</th>
                          <th>{window.$language.STATUS}</th>
                          <th>{window.$language.ACTIONS}</th>
                        </tr>
                      </thead>
                     
                      <tbody>
                        {this.state.prodarr.map((prd) => (
                          <tr>
                            <td> {prd.productCode} </td>
                            <td class="tablerowimage">
                              <img
                                class="img-thumbnail"
                                src={
                                  prd.productImagePath == undefined ||
                                  prd.productImagePath == ""
                                    ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                    : API_URL + "/" + prd.productImagePath
                                }
                                alt="avatar"
                                height="40"
                                width="40"
                              />
                            </td>
                            <td> {prd.productname} </td>
                            <td> {prd.productType} </td>
                            <td>
                              <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                Active
                              </span>
                            </td>
                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <label
                                  class="btn btn-outline-secondary mb-0"
                                  for={"change-picture" + prd.productCode}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Upload Image"
                                >
                                  <i class="fas fa-file-upload"></i>
                                  <input
                                    class="form-control"
                                    type="file"
                                    id={"change-picture" + prd.productCode}
                                    hidden
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={(event) => {
                                      this.uploadPic(event, prd);
                                    }}
                                  />
                                </label>
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  onClick={() => this.Productdetails(prd)}
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View Details"
                                >
                                  <i class="far fa-eye fa-fw"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  data-toggle="modal"
                                  data-target="#modals-slide-in"
                                  onClick={() => this.edit(prd)}
                                  data-placement="top"
                                  title="Edit"
                                >
                                  <i class="far fa-edit fa-fw"></i>
                                </button>
                                <button
                                  onClick={() => this.delete(prd.productCode)}
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                >
                                  <i class="far fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                    {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination mt-3 justify-content-center">
                                        <li class="page-item prev-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                            <li class="page-item active"><a class="page-link" href="javascript:void(0);">1</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                            <li class="page-item next-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                        </ul>
    </nav>*/}
                  </div>
                </div>
              </div>
              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm w-25">
                  <form class="add-new-record modal-content pt-0">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {window.$language.AddNewProduct}
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1" id="inputform">
                      <div class="form-row mb-1">
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.PRODUCTNAME}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="Productname"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-post"
                          >
                            {window.$language.PRODUCTTYPE}
                          </label>
                          <input
                            type="text"
                            id="ProductType"
                            class="form-control dt-post"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.ProductSubType}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="ProductSubType"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.CorrectionRange}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="CorrectionRange"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.BlueFilter}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="BlueFilter"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.TintType}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="TintType"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.TreatmentType}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="TreatmentType"
                            placeholder=""
                            aria-label=""
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1"
                        onClick={() => this.submit()}
                      >
                        {window.$language.Submit}
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                      >
                        {window.$language.Cancel}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
export default Product;
