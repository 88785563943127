import React, { Component } from 'react'

export default class common extends Component 
{
  render() {
    return (
      <div>common</div>
    )
  }
}
export const getUrlVars = () => 
{
  var vars = [], hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for(var i = 0; i < hashes.length; i++)
  {
      hash = hashes[i].split('$*@=');
      vars.push(hash[1]);
      // vars[hash[1]] = hash[1];
  }
  return vars;
}

