import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { API_URL } from "../../config";
import Orderdeta from "./Orderdetails";
import SymLoader1 from "../SymLoader/SymLoader1";
import Eyetestresult from "../EyetestComponent/Eyetestresult";
import SymLoader2 from "../SymLoader/Symloader2";
import $, { event } from "jquery";
//import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import Select from "react-select";
import {
  options,
  options2,
  options3,
  options4,
  options5,
} from "../EyetestComponent/EyetestOptions";
import {
  TiersPayant,
  Typeofpayment,
  colorA1,
  colorA2,
  colorA34,
  LensTypeDetailsA1,
  LensTypeDetailsB2,
  LensTypeDetailsB34,
  TreatmentA1,
  TreatmentSP3,
  Treatment234,
  Typedepanier,
  IndiceA1,
  Indiceothers,
  Typedeteinte,
  Typedevision,
  MontureA1,
  MontureB1,
  Monture2,
  Monture34,
  Supplier,
  frameTypeA1,
  TypedeverreA1,
  TypedeverreA2,
  TypedeverreB1,
  TypedeverreB2,
  TypedeverreSP1,
  TypedeverreSP2,
  TypedeverreSP3,
  Nooption,
} from "./Orderoptions";
import "./Orderpage.css";
import Vieweyetest from "../EyetestComponent/Vieweyetest";

const optionstest = [];

function orderprocessing(props) {
  console.log(options, "options");
  const [state, setState] = useState({
    emailID: "",
    pwd: "",
    loginIP: "",
    loginBrowser: "",
    deviceType: "",
    userLocation: "",
    logIsp: "",
    ii: 0,
    actions: [],
    pLogo: "",
    posarr: [],
    count: 1,
    orderdett: [],
    utype: localStorage.getItem("type"),
    isLoading: true,
    startDate: "",
    endDate: "",
    orderarr: [],
    testname: "",
    patientemailid: "",
    opticianname: "",
    posname: "",
    posemailid: "",
    opticianemailid: "",
    recoOpticianRightSPH: "",
    selectedCrsh: { label: "0.00", value: "0.00" },
    selectedCrcy: { label: "0.00", value: "0.00" },
    selectedCrax: { label: "0.00", value: "0.00" },
    selectedCrad: { label: "0.00", value: "0.00" },
    selectedCrpd: { label: "0.00", value: "0.00" },
    selectedClsh: { label: "0.00", value: "0.00" },
    selectedClcy: { label: "0.00", value: "0.00" },
    selectedClax: { label: "0.00", value: "0.00" },
    selectedClad: { label: "0.00", value: "0.00" },
    selectedClpd: { label: "0.00", value: "0.00" },
    selectedCrsh1: { label: "0.00", value: "0.00" },
    selectedCrcy1: { label: "0.00", value: "0.00" },
    selectedCrax1: { label: "0.00", value: "0.00" },
    selectedCrad1: { label: "0.00", value: "0.00" },
    selectedCrpd1: { label: "0.00", value: "0.00" },
    selectedClsh1: { label: "0.00", value: "0.00" },
    selectedClcy1: { label: "0.00", value: "0.00" },
    selectedClax1: { label: "0.00", value: "0.00" },
    selectedClad1: { label: "0.00", value: "0.00" },
    selectedClpd1: { label: "0.00", value: "0.00" },
    selectedCrsh2: { label: "0.00", value: "0.00" },
    selectedCrcy2: { label: "0.00", value: "0.00" },
    selectedCrax2: { label: "0.00", value: "0.00" },
    selectedCrad2: { label: "0.00", value: "0.00" },
    selectedCrpd2: { label: "0.00", value: "0.00" },
    selectedClsh2: { label: "0.00", value: "0.00" },
    selectedClcy2: { label: "0.00", value: "0.00" },
    selectedClax2: { label: "0.00", value: "0.00" },
    selectedClad2: { label: "0.00", value: "0.00" },
    selectedClpd2: { label: "0.00", value: "0.00" },
    selectedCrsh4: { label: "0.00", value: "0.00" },
    selectedCrcy4: { label: "0.00", value: "0.00" },
    selectedCrax4: { label: "0.00", value: "0.00" },
    selectedCrad4: { label: "0.00", value: "0.00" },
    selectedCrpd4: { label: "0.00", value: "0.00" },
    selectedClsh4: { label: "0.00", value: "0.00" },
    selectedClcy4: { label: "0.00", value: "0.00" },
    selectedClax4: { label: "0.00", value: "0.00" },
    selectedClad4: { label: "0.00", value: "0.00" },
    selectedClpd4: { label: "0.00", value: "0.00" },
  });
  const [selectedOption, setSelectedOption] = useState(null);
  //const [frameType, setframeType] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentItems, setCurrentItems] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [price, setPrice] = useState(0.00);
  const [price2, setPrice2] = useState(0.00);
  const [price3, setPrice3] = useState(0.00);
  const [price4, setPrice4] = useState(0.00);
  const [totalprice, settotalprice] = useState(0.00);
  const [selectedPanier, setSelectedPanier] = useState(null);
  const [selectedTypedeverreA1, setSelectedTypedeverreA1] = useState(null);
  const [selectedTypedeverreA2, setSelectedTypedeverreA2] = useState(null);
  const [selectedTypedeverreA3, setSelectedTypedeverreA3] = useState(null);
  const [selectedTypedeverreA4, setSelectedTypedeverreA4] = useState(null);
  const [selectedLensTypeDetailsA1, setSelectedLensTypeDetailsA1] =
    useState(null);
    const [selectedLensTypeDetailsA2, setSelectedLensTypeDetailsA2] =
    useState(null);
    const [selectedLensTypeDetailsA3, setSelectedLensTypeDetailsA3] =
    useState(null);
    const [selectedLensTypeDetailsA4, setSelectedLensTypeDetailsA4] =
    useState(null);
  const [selectedIndiceA1, setSelectedIndiceA1] = useState(null);
  const [selectedIndiceA2, setSelectedIndiceA2] = useState(null);
  const [selectedIndiceA3, setSelectedIndiceA3] = useState(null);
  const [selectedIndiceA4, setSelectedIndiceA4] = useState(null);
  const [selectedframeTypeA1, setSelectedframeTypeA1] = useState(null);
  const [selectedcolorA1, setSelectedcolorA1] = useState(null);
  const [selectedcolorA2, setSelectedcolorA2] = useState(null);
  const [selectedcolorA3, setSelectedcolorA3] = useState(null);
  const [selectedcolorA4, setSelectedcolorA4] = useState(null);
  const [selectedMontureA1, setSelectedMontureA1] = useState(null);
  const [selectedMontureA2, setSelectedMontureA2] = useState(null);
  const [selectedMontureA3, setSelectedMontureA3] = useState(null);
  const [selectedMontureA4, setSelectedMontureA4] = useState(null);
  const [selectedTreatmentA1, setSelectedTreatmentA1] = useState(null);
  const [selectedTreatmentA2, setSelectedTreatmentA2] = useState(null);
  const [selectedTreatmentA3, setSelectedTreatmentA3] = useState(null);
  const [selectedTreatmentA4, setSelectedTreatmentA4] = useState(null);
  const handlePanierChange = (selectedOption) => {
  if(isEdit == false)
    {
      setSelectedTypedeverreA1(null);
    setSelectedTypedeverreA2(null);
    setSelectedTypedeverreA3(null);
    setSelectedTypedeverreA4(null);
    setSelectedMontureA1(null);
    setSelectedMontureA2(null);
    setSelectedMontureA3(null);
    setSelectedMontureA4(null);
    setSelectedTreatmentA1(null);
    setSelectedTreatmentA2(null);
    setSelectedTreatmentA3(null);
    setSelectedTreatmentA4(null);
    setSelectedcolorA1(null);
    setSelectedcolorA2(null);
    setSelectedcolorA3(null);
    setSelectedcolorA4(null);
    setSelectedIndiceA1(null);
    setSelectedIndiceA2(null);
    setSelectedIndiceA3(null);
    setSelectedIndiceA4(null);
    setSelectedLensTypeDetailsA1(null);
    setSelectedLensTypeDetailsA2(null);
    setSelectedLensTypeDetailsA3(null);
    setSelectedLensTypeDetailsA4(null);
    $(".lensTypeDclass__single-value").text("");
    $(".treatmeclass__single-value").text("");
    $(".typedeverreclass__single-value").text("");
    $(".typedeteinteclass__single-value").text("");
    $(".indiceclass__single-value").text("");
    $(".montureclass__single-value").text("");
    $(".infoFrameclass__single-value").text("");
    $(".frameCoclass__single-value").text("");
    $(".lensTypeDclass1__single-value").text("");
    $(".treatmeclass1__single-value").text("");
    $(".typedeverreclass2__single-value").text("");
    $(".typedeteinteclass2__single-value").text("");
    $(".indiceclass2__single-value").text("");
    $(".montureclass2__single-value").text("");
    $(".infoFrameclass2__single-value").text("");
    $(".frameCoclass1__single-value").text("");
    $(".lensTypeDclass3__single-value").text("");
    $(".treatmeclass3__single-value").text("");
    $(".typedeverreclass3__single-value").text("");
    $(".typedeteinteclass3__single-value").text("");
    $(".indiceclass3__single-value").text("");
    $(".montureclass3__single-value").text("");
    $(".infoFrameclass3__single-value").text("");
    $(".frameCoclass3__single-value").text("");
    $(".lensTypeDclass4__single-value").text("");
    $(".treatmeclass4__single-value").text("");
    $(".typedeverreclass4__single-value").text("");
    $(".typedeteinteclass4__single-value").text("");
    $(".indiceclass4__single-value").text("");
    $(".montureclass4__single-value").text("");
    $(".infoFrameclass4__single-value").text("");
    $(".frameCoclass4__single-value").text("");
    //document.getElementById("price").value = "0.00 €";
    setPrice(0.00);
    setPrice2(0.00);
    setPrice3(0.00);
    setPrice4(0.00);
    settotalprice(0.00)}
    setSelectedPanier(selectedOption ? selectedOption.value : null);
    // You can perform additional actions here if needed
  };
  const extractPrice = (value) => {
    const match = value.match(/- (\d+(\.\d{1,2})?) €/);
    return match ? parseFloat(match[1]) : 0;
  }; // Dependency array includes selectedOption
  useEffect(() => {
    const TypedeverreA1 = selectedTypedeverreA1
      ? extractPrice(selectedTypedeverreA1.value)
      : 0;
    const IndiceA1 = selectedIndiceA1
      ? extractPrice(selectedIndiceA1.value)
      : 0;
    const frameTypeA1 = selectedframeTypeA1
      ? extractPrice(selectedframeTypeA1.value)
      : 0;
    const colorA1 = selectedcolorA1 ? extractPrice(selectedcolorA1.value) : 0;
    const MontureA1 = selectedMontureA1
      ? extractPrice(selectedMontureA1.value)
      : 0;
    const TreatmentA1 = selectedTreatmentA1
      ? extractPrice(selectedTreatmentA1.value)
      : 0;
    const LensTypeDetailsA1 = selectedLensTypeDetailsA1
      ? extractPrice(selectedLensTypeDetailsA1.value)
      : 0;
    setPrice(
      TypedeverreA1 +
        IndiceA1 +
        frameTypeA1 +
        colorA1 +
        MontureA1 +
        TreatmentA1 +
        LensTypeDetailsA1
    );
    const Indice2 = selectedIndiceA2 ? extractPrice(selectedIndiceA2.value) : 0;
    const TypedeverreA2 = selectedTypedeverreA2
      ? extractPrice(selectedTypedeverreA2.value)
      : 0;
      const colorA2 = selectedcolorA2 ? extractPrice(selectedcolorA2.value) : 0;
      const LensTypeDetailsA2 = selectedLensTypeDetailsA2
      ? extractPrice(selectedLensTypeDetailsA2.value)
      : 0;
      const TreatmentA2 = selectedTreatmentA2
      ? extractPrice(selectedTreatmentA2.value)
      : 0;
      const MontureA2 = selectedMontureA2
      ? extractPrice(selectedMontureA2.value)
      : 0;
    setPrice2(Indice2 + TypedeverreA2 + colorA2 + LensTypeDetailsA2 + TreatmentA2 + MontureA2);
    const Indice3 = selectedIndiceA3 ? extractPrice(selectedIndiceA3.value) : 0;
    const TypedeverreA3 = selectedTypedeverreA3
      ? extractPrice(selectedTypedeverreA3.value)
      : 0;
      const colorA3 = selectedcolorA3 ? extractPrice(selectedcolorA3.value) : 0;
      const LensTypeDetailsA3 = selectedLensTypeDetailsA3
      ? extractPrice(selectedLensTypeDetailsA3.value)
      : 0;
      const TreatmentA3 = selectedTreatmentA3
      ? extractPrice(selectedTreatmentA3.value)
      : 0;
      const MontureA3 = selectedMontureA3
      ? extractPrice(selectedMontureA3.value)
      : 0;
    setPrice3(TypedeverreA3 + Indice3 + colorA3 + LensTypeDetailsA3 + TreatmentA3 + MontureA3);
    const Indice4 = selectedIndiceA4 ? extractPrice(selectedIndiceA4.value) : 0;
    const TypedeverreA4 = selectedTypedeverreA4
      ? extractPrice(selectedTypedeverreA4.value)
      : 0;
      const colorA4 = selectedcolorA4 ? extractPrice(selectedcolorA4.value) : 0;
      const LensTypeDetailsA4 = selectedLensTypeDetailsA4
      ? extractPrice(selectedLensTypeDetailsA4.value)
      : 0;
      const TreatmentA4 = selectedTreatmentA4
      ? extractPrice(selectedTreatmentA4.value)
      : 0;
      const MontureA4 = selectedMontureA4
      ? extractPrice(selectedMontureA4.value)
      : 0;
    setPrice4(TypedeverreA4 + Indice4 + colorA4 + LensTypeDetailsA4 + TreatmentA4 + MontureA4);
  const Price= price ? price : 0
  const Price2= price2 ? price2 : 0
  const Price3= price3 ? price3 : 0
  const Price4= price4 ? price4 : 0
  settotalprice(Price + Price2 + Price3 + Price4)

  }, [
    selectedTypedeverreA1,
    selectedIndiceA1,
    selectedframeTypeA1,
    selectedcolorA1,
    selectedMontureA1,
    selectedLensTypeDetailsA1,
    selectedIndiceA2,
    selectedTypedeverreA2,
    selectedTypedeverreA3,
    selectedTypedeverreA4,
    selectedIndiceA3,
    selectedIndiceA4,
    selectedcolorA2,
    selectedcolorA3,
    selectedcolorA4,
    selectedLensTypeDetailsA2,
    selectedLensTypeDetailsA3,
    selectedLensTypeDetailsA4,
    selectedTreatmentA2,
    selectedTreatmentA3,
    selectedTreatmentA4,
    selectedMontureA2,
    selectedMontureA3,
    selectedMontureA4,
    selectedTreatmentA1,
    price,
    price2,
    price3,
    price4,
  ]);
  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handlePageClick = (event) => {
    setPageNumber(event.selected + 1);
    setIsLoading(true);
  };
  const getLocalEyeTest = (search) => {
    setSearchText(search);
  };
  const handleReset = () => {
    localStorage.setItem("hdnsearch", "");
    setStartDate(""); // Reset startDate to an empty string or default value
    setEndDate(""); // Reset endDate to an empty string or default value
    setSearchText("");
    setIsLoading(true);
    //setSelectedOption("");
  };
  console.log(props.val);
  const componentMount = () => {
    document.getElementById("name1").innerHTML =
      props.heading != undefined
        ? props.heading
        : window.$language.ViewAllOrders + ` [${props.det.name}]`;
    document.getElementById("pp2").setAttribute("hidden", "true");
    document.getElementById("pp3").setAttribute("hidden", "true");
    document.getElementById("pp4").setAttribute("hidden", "true");
    if (props.vv == 1) {
      getdata(props.val, props.val, props.val);
    }
    // if (props.vv == 2) {
    //   getdata("","",props.det.patientEmail);
    // }
    else getdata("", "", "");
  };
  useEffect(() => {
    if (isLoading) {
      componentMount();
    }
  }, [isLoading]);
  const showp2 = (val) => {
    if (val == "yes") {
      document.getElementById("pp2").removeAttribute("hidden");
    } else {
      document.getElementById("pp2").setAttribute("hidden", "true");
    setSelectedTypedeverreA2(null);
    setSelectedMontureA2(null);
    setSelectedTreatmentA2(null);
    setSelectedcolorA2(null);
    setSelectedIndiceA2(null);
    setSelectedLensTypeDetailsA2(null);
    $(".lensTypeDclass1__single-value").text("");
    $(".treatmeclass1__single-value").text("");
    $(".typedeverreclass2__single-value").text("");
    $(".typedeteinteclass2__single-value").text("");
    $(".indiceclass2__single-value").text("");
    $(".montureclass2__single-value").text("");
    $(".infoFrameclass2__single-value").text("");
    $(".frameCoclass1__single-value").text("");
    //document.getElementById("price").value = "0.00 €";
    setPrice2(0.00);
    }
  };
  const showp3 = (val) => {
    if (val == "yes") {
      document.getElementById("pp3").removeAttribute("hidden");
    } else {
      document.getElementById("pp3").setAttribute("hidden", "true");
    setSelectedTypedeverreA3(null);
    setSelectedMontureA3(null);
    setSelectedTreatmentA3(null);
    setSelectedcolorA3(null);
    setSelectedIndiceA3(null);
    setSelectedLensTypeDetailsA3(null);
    $(".lensTypeDclass3__single-value").text("");
    $(".treatmeclass3__single-value").text("");
    $(".typedeverreclass3__single-value").text("");
    $(".typedeteinteclass3__single-value").text("");
    $(".indiceclass3__single-value").text("");
    $(".montureclass3__single-value").text("");
    $(".infoFrameclass3__single-value").text("");
    $(".frameCoclass3__single-value").text("");
    setPrice3(0.00);
    }
  };
  const showp4 = (val) => {
    if (val == "yes") {
      document.getElementById("pp4").removeAttribute("hidden");
    } else {
      document.getElementById("pp4").setAttribute("hidden", "true");
    setSelectedTypedeverreA4(null);
    setSelectedMontureA4(null);
    setSelectedTreatmentA4(null);
    setSelectedcolorA4(null);
    setSelectedIndiceA4(null);
    setSelectedLensTypeDetailsA4(null);
    $(".lensTypeDclass4__single-value").text("");
    $(".treatmeclass4__single-value").text("");
    $(".typedeverreclass4__single-value").text("");
    $(".typedeteinteclass4__single-value").text("");
    $(".indiceclass4__single-value").text("");
    $(".montureclass4__single-value").text("");
    $(".infoFrameclass4__single-value").text("");
    $(".frameCoclass4__single-value").text("");
    //document.getElementById("price").value = "0.00 €";
    setPrice4(0.00);
    }
  };
  const getdata = (pe, status, search = "") => {
    //debugger;
    console.log(props.det.patientEmail, "Patientsdata");
    $("#overlay2").css("display", "block");
    setIsLoading(true);
    var searchreturn = "";
    if (searchText != "") {
      localStorage.setItem("hdnsearch", searchText);
      searchreturn = searchText;
    } else {
      searchreturn =
        searchText == "" && localStorage.getItem("hdnsearch") == ""
          ? searchText
          : localStorage.getItem("hdnsearch");
      if (props.det.patientEmail != "" && props.det.patientEmail != undefined) {
        searchreturn = props.det.patientEmail;
      }
    }
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        patientEmail: pe,
        status: status,
        search: searchreturn,
        // search: searchText == "" ? props.det.patientEmail : searchText,
        pageNumber: pageNumber,
        pageSize: itemsPerPage,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    let logourl = API_URL + "/Orders/searchweb";
    // let logourl = API_URL + "/Orders/search";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        console.log(props);
        console.log(
          Math.ceil(json.totalItems / itemsPerPage),
          "jkdsajkdsajsdajk"
        );
        setPageCount(Math.ceil(json.totalItems / itemsPerPage));
        if (json.orderCombinedDatas == null) {
          setIsLoading(false);
        } else {
          let currentDetails = json.orderCombinedDatas.filter(
            (val) => val.email === state.emailID
          );
          console.log("currentADetails", currentDetails);

          {
            setState((prevState) => {
              return { ...prevState, orderdett: json.orderCombinedDatas };
            });
            setCurrentItems(json.orderCombinedDatas);
          }
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {}, [state]);

  const notify = () => {
    let leng = document.getElementsByName("ordernumstat").length;
    //alert(leng)
    let arr1 = [];
    for (let i = 0; i < leng; i++) {
      if (document.getElementsByName("ordernumstat")[i].checked) {
        arr1.push(document.getElementsByName("ordernumstat")[i].value);
      }
    }
    let msg =
      document.getElementById("orderstat").value == "Annulé"
        ? "Annulée"
        : document.getElementById("orderstat").value == "Pending"
        ? window.$language.Pending
        : document.getElementById("orderstat").value == "Commandé"
        ? "Commandée"
        : document.getElementById("orderstat").value == "ToBeOrdered"
        ? "À commander"
        : "SAV";
    console.log(arr1);
    swal({
      title: "Souhaitez-vous confirmer cette modification ?",
      text:
        ' En cliquant sur OK, le statut de la commande sera changé en "' +
        msg +
        '" ?',
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/Orders/ChangeStatus";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            ordernos: arr1,
            status: document.getElementById("orderstat").value,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: window.$language.OrderStatusChangedSuccessfully,
                icon: "success",
              });
              componentMount();
              document.getElementById("close1122").click();
              for (let i = 0; i < leng; i++) {
                document.getElementsByName("ordernumstat")[i].checked = false;
              }
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  };
  const submit = async () => {
    if (
      ($(".typedevisionclass__single-value").text() == "Progressif" ||
        $(".typedevisionclass__single-value").text() == "Degressif" ||
        $(".typedevisionclass__single-value").text() == "Bifocal") &&
      ($(".cladclass__single-value").text() == "0.00" ||
        $(".cradclass__single-value").text() == "0.00")
    ) {
      swal({
        title: "OOPS!",
        text: "Il n'est pas possible de soumettre une commande de correction progressive sans saisir des valeurs ADD.",
        icon: "error",
      });
      return;
    }

    if (
      document.getElementById("Heiel").value == "" ||
      document.getElementById("Heier").value == "" ||
      $(".clpdclass__single-value").text() == "0.00" ||
      $(".crpdclass__single-value").text() == "0.00" ||
      $(".indiceclass__single-value").text() == "" ||
      $(".lensShapeclass__single-value").text() == "" ||
      $(".tiersPayaclass__single-value").text() == "" ||
      $(".typedeverreclass__single-value").text() == "" ||
      $(".typeofpayclass__single-value").text() == "" ||
      $(".treatmeclass__single-value").text() == "" ||
      $(".typedevisionclass__single-value").text() == "" ||
      $(".montureclass__single-value").text() == ""||
      $(".infoFrameclass__single-value").text() == ""||
        $(".supplierclass__single-value").text() == ""
    ) {
      swal({
        title: "OOPS!",
        text: "Merci de bien vérifier que toutes les options obligatoires marquées par un * ont été remplies pour pouvoir soumettre cette commande.",
        icon: "warning",
      });
      return;
    } else if ($('input[name="Secondpair"]:checked').val() == "yes") {
      if (
        ($(".typedevisionclass2__single-value").text() == "Progressif" ||
          $(".typedevisionclass2__single-value").text() == "Degressif" ||
          $(".typedevisionclass2__single-value").text() == "Bifocal") &&
        ($(".clad1class__single-value").text() == "0.00" ||
          $(".crad1class__single-value").text() == "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre une commande de correction progressive sans saisir des valeurs ADD.",
          icon: "error",
        });
        return;
      }
      if (
        $("#Heiel1").val() == "" ||
        $("#Heier1").val() == "" ||
        $(".clpd1class__single-value").text() == "0.00" ||
        $(".crpd1class__single-value").text() == "0.00" ||
        $(".indiceclass2__single-value").text() == "" ||
        $(".lensShapeclass__single-value").text() == "" ||
        $(".tiersPayaclass__single-value").text() == "" ||
        $(".typedeverreclass2__single-value").text() == "" ||
        $(".treatmeclass1__single-value").text() == "" ||
        $(".typedevisionclass2__single-value").text() == ""||
        $(".montureclass2__single-value").text() == ""||
        $(".infoFrameclass2__single-value").text() == ""||
        $(".supplierclass2__single-value").text() == ""
      ) {
        swal({
          title: "OOPS!",
          text: "Merci de bien vérifier que toutes les options obligatoires marquées par un * ont été remplies pour pouvoir soumettre cette commande.",
          icon: "warning",
        });
        return;
      }
    } else if ($('input[name="thirdpair"]:checked').val() == "yes") {
      if (
        ($(".typedevisionclass3__single-value").text() == "Progressif" ||
          $(".typedevisionclass3__single-value").text() == "Degressif" ||
          $(".typedevisionclass3__single-value").text() == "Bifocal") &&
        ($(".clad2class__single-value").text() == "0.00" ||
          $(".crad2class__single-value").text() == "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre une commande de correction progressive sans saisir des valeurs ADD.",
          icon: "error",
        });
        return;
      }
      if (
        $("#Heiel3").val() == "" ||
        $("#Heier3").val() == "" ||
        $(".clpd2class__single-value").text() == "0.00" ||
        $(".crpd2class__single-value").text() == "0.00" ||
        $(".indiceclass3__single-value").text() == "" ||
        $(".lensShapeclass__single-value").text() == "" ||
        $(".tiersPayaclass__single-value").text() == "" ||
        $(".typedeverreclass3__single-value").text() == "" ||
        $(".treatmeclass3__single-value").text() == "" ||
        $(".typedevisionclass3__single-value").text() == ""||
        $(".montureclass3__single-value").text() == ""||
        $(".infoFrameclass3__single-value").text() == ""||
        $(".supplierclass3__single-value").text() == ""
      ) {
        swal({
          title: "OOPS!",
          text: "Merci de bien vérifier que toutes les options obligatoires marquées par un * ont été remplies pour pouvoir soumettre cette commande.",
          icon: "warning",
        });
        return;
      }
    } else if ($('input[name="Forthpair"]:checked').val() == "yes") {
      if (
        ($(".typedevisionclass4__single-value").text() == "Progressif" ||
          $(".typedevisionclass4__single-value").text() == "Degressif" ||
          $(".typedevisionclass4__single-value").text() == "Bifocal") &&
        ($(".clad3class__single-value").text() == "0.00" ||
          $(".crad3class__single-value").text() == "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre une commande de correction progressive sans saisir des valeurs ADD.",
          icon: "error",
        });
        return;
      }
      if (
        $("#Heiel4").val() == "" ||
        $("#Heier4").val() == "" ||
        $(".clpd3class__single-value").text() == "0.00" ||
        $(".crpd3class__single-value").text() == "0.00" ||
        $(".indiceclass4__single-value").text() == "" ||
        $(".typedeverreclass4__single-value").text() == "" ||
        $(".lensShapeclass__single-value").text() == "" ||
        $(".tiersPayaclass__single-value").text() == "" ||
        $(".treatmeclass4__single-value").text() == "" ||
        $(".typedevisionclass4__single-value").text() == ""||
        $(".montureclass4__single-value").text() == ""||
        $(".infoFrameclass4__single-value").text() == ""||
        $(".supplierclass4__single-value").text() == ""
      ) {
        swal({
          title: "OOPS!",
          text: "Merci de bien vérifier que toutes les options obligatoires marquées par un * ont été remplies pour pouvoir soumettre cette commande.",
          icon: "warning",
        });
        return;
      }
    }
    let addEditOrderModels = [];
    let data = {
      orderNumber: localStorage.getItem("ordernumber"),
      patientEmail:
        isEdit == false ? props.det.patientEmail : state.patientemailid,
      doctorEmail: "",
      opticianEmail:
        isEdit == false ? props.det.opticianEmail : state.opticianemailid,
      posEmail: isEdit == false ? props.det.posEmail : state.posemailid,
      testName: isEdit == false ? props.det.testName : state.testname,
      status: $('input[name="status1"]:checked').val(),
      recoOpticianRightSPH: document.getElementById("rsph").value,
      recoOpticianRightCYL: document.getElementById("rcyl").value,
      recoOpticianRightAXIS: document.getElementById("raxe").value,
      recoOpticianRightADD: document.getElementById("radd").value,
      recoOpticianRightPD: document.getElementById("rpd").value,
      recoOpticianRightPDHIGH: "0.00",
      recoOpticianLeftSPH: document.getElementById("lsph").value,
      recoOpticianLeftCYL: document.getElementById("lcyl").value,
      recoOpticianLeftAXIS: document.getElementById("laxe").value,
      recoOpticianLeftADD: document.getElementById("ladd").value,
      recoOpticianLeftPD: document.getElementById("lpd").value,
      recoOpticianLeftPDHIGH: "0.00",
      orderRightSPH: $(".crshclass__single-value").text(),
      orderRightCYL: $(".crcyclass__single-value").text(),
      orderRightAXIS: $(".craxclass__single-value").text(),
      orderRightADD: $(".cradclass__single-value").text(),
      orderRightPD: $(".crpdclass__single-value").text(),
      orderRightPDHIGH: "0.00",
      orderLeftSPH: $(".clshclass__single-value").text(),
      orderLeftCYL: $(".clcyclass__single-value").text(),
      orderLeftAXIS: $(".claxclass__single-value").text(),
      orderLeftADD: $(".cladclass__single-value").text(),
      orderLeftPD: $(".clpdclass__single-value").text(),
      orderLeftPDHIGH: "0.00",
      orderType: $(".typedeverreclass__single-value").text(),
      orderTint: $(".typedeteinteclass__single-value").text(),
      orderIndex: $(".indiceclass__single-value").text(),
      lensShape: $(".lensShapeclass__single-value").text(),
      comments: document.getElementById("com1").value,
      otherComments: document.getElementById("ocom1").value,
      leftHeightEye: document.getElementById("Heiel").value,
      rightHeightEye: document.getElementById("Heier").value,
      price: document.getElementById("price").value,
      frameReference: $(".montureclass__single-value").text(),
      eyetestId: props.det.testid,
      testUniqueCode: props.det.testUniqueCode,
      OrderPairNo: "1",
      frameColor: $(".frameCoclass__single-value").text(),
      tiersPayant: $(".tiersPayaclass__single-value").text(),
      typeofpayment: $(".typeofpayclass__single-value").text(),
      lensTypeDetails: $(".lensTypeDclass__single-value").text(),
      lensType: "",
      treatment: $(".treatmeclass__single-value").text(),
      tinttype: "",
      typeDeVision: $(".typedevisionclass__single-value").text(),
      infoSupplier: $(".supplierclass__single-value").text(),
      infoFrameType: $(".infoFrameclass__single-value").text(),
      InfoSubmitTrack: "Web",
    };
    console.log(data, "data");
    console.log("this.submit2");

    // await submit2(data);
    addEditOrderModels.push(data);
    if ($('input[name="Secondpair"]:checked').val() == "yes") {
      console.log("pair2");
      // alert()
      let data1 = {
        orderNumber: localStorage.getItem("ordernumber1"),
        patientEmail:
          isEdit == false ? props.det.patientEmail : state.patientemailid,
        doctorEmail: "string",
        opticianEmail:
          isEdit == false ? props.det.opticianEmail : state.opticianemailid,
        posEmail: isEdit == false ? props.det.posEmail : state.posemailid,
        testName: isEdit == false ? props.det.testName : state.testname,
        status: $('input[name="status2"]:checked').val(),
        recoOpticianRightSPH: document.getElementById("rsph").value,
        recoOpticianRightCYL: document.getElementById("rcyl").value,
        recoOpticianRightAXIS: document.getElementById("raxe").value,
        recoOpticianRightADD: document.getElementById("radd").value,
        recoOpticianRightPD: document.getElementById("rpd").value,
        recoOpticianRightPDHIGH: "0.00",
        recoOpticianLeftSPH: document.getElementById("lsph").value,
        recoOpticianLeftCYL: document.getElementById("lcyl").value,
        recoOpticianLeftAXIS: document.getElementById("laxe").value,
        recoOpticianLeftADD: document.getElementById("ladd").value,
        recoOpticianLeftPD: document.getElementById("lpd").value,
        recoOpticianLeftPDHIGH: "0.00",
        orderRightSPH: $(".crsh1class__single-value").text(),
        orderRightCYL: $(".crcy1class__single-value").text(),
        orderRightAXIS: $(".crax1class__single-value").text(),
        orderRightADD: $(".crad1class__single-value").text(),
        orderRightPD: $(".crpd1class__single-value").text(),
        orderRightPDHIGH: "0.00",
        orderLeftSPH: $(".clsh1class__single-value").text(),
        orderLeftCYL: $(".clcy1class__single-value").text(),
        orderLeftAXIS: $(".clax1class__single-value").text(),
        orderLeftADD: $(".clad1class__single-value").text(),
        orderLeftPD: $(".clpd1class__single-value").text(),
        orderLeftPDHIGH: "0.00",
        orderType: $(".typedeverreclass2__single-value").text(),
        orderTint: $(".typedeteinteclass2__single-value").text(),
        orderIndex: $(".indiceclass2__single-value").text(),
        lensShape: $(".lensShapeclass__single-value").text(),
        comments: document.getElementById("com21").value,
        otherComments: document.getElementById("ocom21").value,
        leftHeightEye: document.getElementById("Heiel1").value,
        rightHeightEye: document.getElementById("Heier1").value,
        price: document.getElementById("price1").value,
        frameReference: $(".montureclass2__single-value").text(),
        eyetestId: props.det.testid,
        testUniqueCode: props.det.testUniqueCode,
        InfoSubmitTrack: "Web",
        OrderPairNo: "2",
        //orderTint:$(".orderTiclass1__single-value").text(),
        frameColor: $(".frameCoclass1__single-value").text(),
        tiersPayant: $(".tiersPayaclass__single-value").text(),
        typeofpayment: $(".typeofpayclass__single-value").text(),
        lensTypeDetails: $(".lensTypeDclass1__single-value").text(),
        lensType: "",
        treatment: $(".treatmeclass1__single-value").text(),
        tinttype: "",
        typeDeVision: $(".typedevisionclass2__single-value").text(),
        infoSupplier: $(".supplierclass2__single-value").text(),
        infoFrameType: $(".infoFrameclass2__single-value").text(),
      };
      console.log(data1, "data2");
      //await submit5(data1);
      addEditOrderModels.push(data1);
    }
    if ($('input[name="thirdpair"]:checked').val() == "yes") {
      //console.log("pair3");
      // alert()
      let data2 = {
        orderNumber: localStorage.getItem("ordernumber2"),
        patientEmail:
          isEdit == false ? props.det.patientEmail : state.patientemailid,
        doctorEmail: "string",
        opticianEmail:
          isEdit == false ? props.det.opticianEmail : state.opticianemailid,
        posEmail: isEdit == false ? props.det.posEmail : state.posemailid,
        testName: isEdit == false ? props.det.testName : state.testname,
        status: $('input[name="status3"]:checked').val(),
        recoOpticianRightSPH: document.getElementById("rsph").value,
        recoOpticianRightCYL: document.getElementById("rcyl").value,
        recoOpticianRightAXIS: document.getElementById("raxe").value,
        recoOpticianRightADD: document.getElementById("radd").value,
        recoOpticianRightPD: document.getElementById("rpd").value,
        recoOpticianRightPDHIGH: "0.00",
        recoOpticianLeftSPH: document.getElementById("lsph").value,
        recoOpticianLeftCYL: document.getElementById("lcyl").value,
        recoOpticianLeftAXIS: document.getElementById("laxe").value,
        recoOpticianLeftADD: document.getElementById("ladd").value,
        recoOpticianLeftPD: document.getElementById("lpd").value,
        recoOpticianLeftPDHIGH: "0.00",
        orderRightSPH: $(".crsh2class__single-value").text(),
        orderRightCYL: $(".crcy2class__single-value").text(),
        orderRightAXIS: $(".crax2class__single-value").text(),
        orderRightADD: $(".crad2class__single-value").text(),
        orderRightPD: $(".crpd2class__single-value").text(),
        orderRightPDHIGH: "0.00",
        orderLeftSPH: $(".clsh2class__single-value").text(),
        orderLeftCYL: $(".clcy2class__single-value").text(),
        orderLeftAXIS: $(".clax2class__single-value").text(),
        orderLeftADD: $(".clad2class__single-value").text(),
        orderLeftPD: $(".clpd2class__single-value").text(),
        orderLeftPDHIGH: "0.00",
        orderType: $(".typedeverreclass3__single-value").text(),
        orderTint: $(".typedeteinteclass3__single-value").text(),
        orderIndex: $(".indiceclass3__single-value").text(),
        lensShape: $(".lensShapeclass__single-value").text(),
        comments: document.getElementById("com23").value,
        otherComments: document.getElementById("ocom23").value,
        leftHeightEye: document.getElementById("Heiel3").value,
        rightHeightEye: document.getElementById("Heier3").value,
        price: document.getElementById("price3").value,
        frameReference: $(".montureclass3__single-value").text(),
        eyetestId: props.det.testid,
        testUniqueCode: props.det.testUniqueCode,
        OrderPairNo: "3",
        //orderTint:$(".orderTiclass3__single-value").text(),
        frameColor: $(".frameCoclass3__single-value").text(),
        tiersPayant: $(".tiersPayaclass__single-value").text(),
        typeofpayment: $(".typeofpayclass__single-value").text(),
        lensTypeDetails: $(".lensTypeDclass3__single-value").text(),
        lensType: "",
        treatment: $(".treatmeclass3__single-value").text(),
        tinttype: "",
        typeDeVision: $(".typedevisionclass3__single-value").text(),
        infoSupplier: $(".supplierclass3__single-value").text(),
        infoFrameType: $(".infoFrameclass3__single-value").text(),
        InfoSubmitTrack: "Web",
      };
      console.log(data2, "data3");
      // await submit3(data2);
      addEditOrderModels.push(data2);
    }
    if ($('input[name="Forthpair"]:checked').val() == "yes") {
      let data3 = {
        orderNumber: localStorage.getItem("ordernumber3"),
        patientEmail:
          isEdit == false ? props.det.patientEmail : state.patientemailid,
        doctorEmail: "string",
        opticianEmail:
          isEdit == false ? props.det.opticianEmail : state.opticianemailid,
        posEmail: isEdit == false ? props.det.posEmail : state.posemailid,
        testName: isEdit == false ? props.det.testName : state.testname,
        status: $('input[name="status4"]:checked').val(),
        recoOpticianRightSPH: document.getElementById("rsph").value,
        recoOpticianRightCYL: document.getElementById("rcyl").value,
        recoOpticianRightAXIS: document.getElementById("raxe").value,
        recoOpticianRightADD: document.getElementById("radd").value,
        recoOpticianRightPD: document.getElementById("rpd").value,
        recoOpticianRightPDHIGH: "0.00",
        recoOpticianLeftSPH: document.getElementById("lsph").value,
        recoOpticianLeftCYL: document.getElementById("lcyl").value,
        recoOpticianLeftAXIS: document.getElementById("laxe").value,
        recoOpticianLeftADD: document.getElementById("ladd").value,
        recoOpticianLeftPD: document.getElementById("lpd").value,
        recoOpticianLeftPDHIGH: "0.00",
        orderRightSPH: $(".crsh3class__single-value").text(),
        orderRightCYL: $(".crcy3class__single-value").text(),
        orderRightAXIS: $(".crax3class__single-value").text(),
        orderRightADD: $(".crad3class__single-value").text(),
        orderRightPD: $(".crpd3class__single-value").text(),
        orderRightPDHIGH: "0.00",
        orderLeftSPH: $(".clsh3class__single-value").text(),
        orderLeftCYL: $(".clcy3class__single-value").text(),
        orderLeftAXIS: $(".clax3class__single-value").text(),
        orderLeftADD: $(".clad3class__single-value").text(),
        orderLeftPD: $(".clpd3class__single-value").text(),
        orderLeftPDHIGH: "0.00",
        orderType: $(".typedeverreclass4__single-value").text(),
        orderTint: $(".typedeteinteclass4__single-value").text(),
        orderIndex: $(".indiceclass4__single-value").text(),
        lensShape: $(".lensShapeclass__single-value").text(),
        comments: document.getElementById("com24").value,
        otherComments: document.getElementById("ocom24").value,
        leftHeightEye: document.getElementById("Heiel4").value,
        rightHeightEye: document.getElementById("Heier4").value,
        price: document.getElementById("price4").value,
        frameReference: $(".montureclass4__single-value").text(),
        eyetestId: props.det.testid,
        testUniqueCode: props.det.testUniqueCode,
        OrderPairNo: "4",
        //orderTint:$(".orderTiclass4__single-value").text(),
        frameColor: $(".frameCoclass4__single-value").text(),
        tiersPayant: $(".tiersPayaclass__single-value").text(),
        typeofpayment: $(".typeofpayclass__single-value").text(),
        lensTypeDetails: $(".lensTypeDclass4__single-value").text(),
        lensType: "",
        treatment: $(".treatmeclass4__single-value").text(),
        tinttype: "",
        typeDeVision: $(".typedevisionclass4__single-value").text(),
        infoSupplier: $(".supplierclass4__single-value").text(),
        infoFrameType: $(".infoFrameclass4__single-value").text(),
        InfoSubmitTrack: "Web",
      };
      console.log(data3, "pair4");
      // await submit4(data3);
      addEditOrderModels.push(data3);
    }
    $("#btnsubmit,#close1243").prop("disabled", true);
    let logourl =
      isEdit == true
        ? API_URL + "/Orders/modify"
        : API_URL + "/Orders/addmultiple";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(addEditOrderModels),
    };

    await fetch(logourl, requestOptions)
      // .then(res => res.json())
      .then((json) => {
        //  setState({posarr:json})
        $("#btnsubmit,#close1243").prop("disabled", false);
        swal({
          title: "Done!",
          text: window.$language.AddedSuccessfully,
          icon: "success",
        });
        document.getElementById("close1243").click();
        getdata("", "", "");
      });
  };
  const Orderdetails = (det) => {
    //debugger;
    det = det.reverse();
    setState((prevState) => {
      return { ...prevState, orderdett: det };
    });
    orderdeta(det);
    // $.each(det, function( index, value )
    // {
    //   orderdeta(value);
    // });

    // var token = localStorage.getItem("token");
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: token,
    //   },
    //   body: JSON.stringify({
    //     search: orderNumber,
    //     pageNumber: 1,
    //     pageSize: itemsPerPage,
    //   }),
    // };
    // // let logourl = API_URL + "/Orders/search";
    // let logourl = API_URL + "/Orders/searchweb";
    // fetch(logourl, requestOptions)
    //   .then((res) => res.json())
    //   .then((json) =>
    //   {
    //     setState((prevState) => {
    //       return { ...prevState, orderdett: json.orderModelList };
    //     });
    //     orderdeta(json.orderModelList[0]);
    //   })
    //   .catch((e) => {
    //     swal({
    //       title: "OOPS!",
    //       text: window.$language.Somethingwentwrong,
    //       icon: "warning",
    //     });
    //     console.log(e);
    //   });
  };
  const orderdeta = (det) => {
    console.log("hi");
    ReactDOM.render(
      <Orderdeta val={props.val} vv={props.vv} det1={det} det={props.det} />,
      document.getElementById("c11")
    );
  };
  const edit = (det) => {
    console.log(det, "details");
    setIsEdit(true);
    setState((prevState) => {
      return { ...prevState, count: 2 };
    });
    prereset();
    console.log(det.orderTint, "tint");
    document.getElementsByClassName("patientemailclass").value =
      det[0].patientEmail;
    document.getElementsByClassName("testnameclass").value = det[0].testName;
    document.getElementsByClassName("optnameclass").value = det[0].opticianName;
    document.getElementsByClassName("posnameclass").value = det[0].posName;

    for (let i = 0; i < det.length; i++) {
      if (det[i].infoOrderPair == "1") {
        //setPrice(extractPrice1(det[i].price));
        setSelectedPanier(det[i].lensShape);
        //setSelectedTypedeverreA1(det[i].orderType);
        document.getElementById("rsph").value = det[i].recoOpticianRightSPH;
        document.getElementById("rcyl").value = det[i].recoOpticianRightCYL;
        document.getElementById("raxe").value = det[i].recoOpticianRightAXIS;
        document.getElementById("radd").value = det[i].recoOpticianRightADD;
        document.getElementById("rpd").value = det[i].recoOpticianRightPD;
        document.getElementById("lsph").value = det[i].recoOpticianLeftSPH;
        document.getElementById("lcyl").value = det[i].recoOpticianLeftCYL;
        document.getElementById("laxe").value = det[i].recoOpticianLeftAXIS;
        document.getElementById("ladd").value = det[i].recoOpticianLeftADD;
        document.getElementById("lpd").value = det[i].recoOpticianLeftPD;
        $(".crshclass__single-value").text(det[i].orderRightSPH);
        $(".crcyclass__single-value").text(det[i].orderRightCYL);
        $(".craxclass__single-value").text(det[i].orderRightAXIS);
        $(".cradclass__single-value").text(det[i].orderRightADD);
        $(".crpdclass__single-value").text(det[i].orderRightPD);
        $(".clshclass__single-value").text(det[i].orderLeftSPH);
        $(".clcyclass__single-value").text(det[i].orderLeftCYL);
        $(".claxclass__single-value").text(det[i].orderLeftAXIS);
        $(".cladclass__single-value").text(det[i].orderLeftADD);
        $(".clpdclass__single-value").text(det[i].orderLeftPD);
        $(".montureclass__single-value").text(det[i].frameReference);
        document.getElementById("com1").value = det[i].comments;
        document.getElementById("ocom1").value = det[i].otherComments;
        document.getElementById("Heier").value = det[i].rightHeightEye;
        document.getElementById("Heiel").value = det[i].leftHeightEye;
        localStorage.setItem("ordernumber", det[i].orderNumber);
        $(".frameCoclass__single-value").text(det[i].frameColor);
        $(".tiersPayaclass__single-value").text(det[i].tiersPayant);
        $(".lensTypeDclass__single-value").text(det[i].lensTypeDetails);
        $(".treatmeclass__single-value").text(det[i].treatment);
        $(".typedeverreclass__single-value").text(det[i].orderType);
        $(".typedeteinteclass__single-value").text(det[i].orderTint);
        $(".indiceclass__single-value").text(det[i].orderIndex);
        $(".lensShapeclass__single-value").text(det[i].lensShape);
        $(".typedevisionclass__single-value").text(det[i].typeDeVision);
        $(".supplierclass__single-value").text(det[i].supplier);
        $(".infoFrameclass__single-value").text(det[i].frameType);
        $(".typeofpayclass__single-value").text(det[i].typeofpayment);
        $("#price").val(det[i].price);
        //setPrice(det[i].price);
        
      }
      if (det[i].infoOrderPair == "2") {
        $("#validationRadio17").prop("checked", true);
        showp2("yes");
        setSelectedPanier(det[i].lensShape);
        $(".lensShapeclass__single-value").text(det[i].lensShape);
        $(".typedeverreclass2__single-value").text(det[i].orderType);
        $(".typedeteinteclass2__single-value").text(det[i].orderTint);
        $(".indiceclass2__single-value").text(det[i].orderIndex);
        $(".crsh1class__single-value").text(det[i].orderRightSPH);
        $(".crcy1class__single-value").text(det[i].orderRightCYL);
        $(".crax1class__single-value").text(det[i].orderRightAXIS);
        $(".crad1class__single-value").text(det[i].orderRightADD);
        $(".crpd1class__single-value").text(det[i].orderRightPD);
        $(".clsh1class__single-value").text(det[i].orderLeftSPH);
        $(".clcy1class__single-value").text(det[i].orderLeftCYL);
        $(".clax1class__single-value").text(det[i].orderLeftAXIS);
        $(".clad1class__single-value").text(det[i].orderLeftADD);
        $(".clpd1class__single-value").text(det[i].orderLeftPD);
        $(".montureclass2__single-value").text(det[i].frameReference);
        document.getElementById("com21").value = det[i].comments;
        document.getElementById("ocom21").value = det[i].otherComments;
        document.getElementById("Heier1").value = det[i].rightHeightEye;
        document.getElementById("Heiel1").value = det[i].leftHeightEye;
        localStorage.setItem("ordernumber1", det[i].orderNumber);
        $(".frameCoclass1__single-value").text(det[i].frameColor);
        $(".tiersPayaclass__single-value").text(det[i].tiersPayant);
        $(".typeofpayclass__single-value").text(det[i].typeofpayment);
        $(".lensTypeDclass1__single-value").text(det[i].lensTypeDetails);
        $(".lensTypclass1__single-value").text(det[i].lensType);
        $(".treatmeclass1__single-value").text(det[i].treatment);
        $(".typedevisionclass2__single-value").text(det[i].typeDeVision);
        $(".supplierclass2__single-value").text(det[i].supplier);
        $(".infoFrameclass2__single-value").text(det[i].frameType);
        document.getElementById("price1").value = det[i].price;
        //$(".tinttyclass1__single-value").text(det[i].tinttype);
      }
      if (det[i].infoOrderPair == "3") {
        $("#validationRadio178").prop("checked", true);
        showp3("yes");
        setSelectedPanier(det[i].lensShape);
        $(".lensShapeclass__single-value").text(det[i].lensShape);
        $(".typedeverreclass3__single-value").text(det[i].orderType);
        $(".typedeteinteclass3__single-value").text(det[i].orderTint);
        $(".indiceclass3__single-value").text(det[i].orderIndex);
        $(".crsh2class__single-value").text(det[i].orderRightSPH);
        $(".crcy2class__single-value").text(det[i].orderRightCYL);
        $(".crax2class__single-value").text(det[i].orderRightAXIS);
        $(".crad2class__single-value").text(det[i].orderRightADD);
        $(".crpd2class__single-value").text(det[i].orderRightPD);
        $(".clsh2class__single-value").text(det[i].orderLeftSPH);
        $(".clcy2class__single-value").text(det[i].orderLeftCYL);
        $(".clax2class__single-value").text(det[i].orderLeftAXIS);
        $(".clad2class__single-value").text(det[i].orderLeftADD);
        $(".clpd2class__single-value").text(det[i].orderLeftPD);
        $(".montureclass3__single-value").text(det[i].frameReference);
        document.getElementById("com23").value = det[i].comments;
        document.getElementById("ocom23").value = det[i].otherComments;
        document.getElementById("Heier3").value = det[i].rightHeightEye;
        document.getElementById("Heiel3").value = det[i].leftHeightEye;
        localStorage.setItem("ordernumber2", det[i].orderNumber);
        $(".frameCoclass3__single-value").text(det[i].frameColor);
        $(".tiersPayaclass__single-value").text(det[i].tiersPayant);
        $(".typeofpayclass__single-value").text(det[i].typeofpayment);
        $(".lensTypeDclass3__single-value").text(det[i].lensTypeDetails);
        $(".treatmeclass3__single-value").text(det[i].treatment);
        $(".typedevisionclass3__single-value").text(det[i].typeDeVision);
        $(".supplierclass3__single-value").text(det[i].supplier);
        $(".infoFrameclass3__single-value").text(det[i].frameType);
        //$(".tinttyclass3__single-value").text(det[i].tinttype);
        document.getElementById("price3").value = det[i].price;
      }
      if (det[i].infoOrderPair == "4") {
        $("#validationRadio179").prop("checked", true);
        showp4("yes");
        setSelectedPanier(det[i].lensShape);
        $(".lensShapeclass__single-value").text(det[i].lensShape);
        $(".typedeverreclass4__single-value").text(det[i].orderType);
        $(".typedeteinteclass4__single-value").text(det[i].orderTint);
        $(".indiceclass4__single-value").text(det[i].orderIndex);
        $(".crsh3class__single-value").text(det[i].orderRightSPH);
        $(".crcy3class__single-value").text(det[i].orderRightCYL);
        $(".crax3class__single-value").text(det[i].orderRightAXIS);
        $(".crad3class__single-value").text(det[i].orderRightADD);
        $(".crpd3class__single-value").text(det[i].orderRightPD);
        $(".clsh3class__single-value").text(det[i].orderLeftSPH);
        $(".clcy3class__single-value").text(det[i].orderLeftCYL);
        $(".clax3class__single-value").text(det[i].orderLeftAXIS);
        $(".clad3class__single-value").text(det[i].orderLeftADD);
        $(".clpd3class__single-value").text(det[i].orderLeftPD);
        $(".montureclass4__single-value").text(det[i].frameReference);
        document.getElementById("com24").value = det[i].comments;
        document.getElementById("ocom24").value = det[i].otherComments;
        document.getElementById("Heier4").value = det[i].rightHeightEye;
        document.getElementById("Heiel4").value = det[i].leftHeightEye;
        localStorage.setItem("ordernumber3", det[i].orderNumber);
        $(".frameCoclass4__single-value").text(det[i].frameColor);
        $(".tiersPayaclass__single-value").text(det[i].tiersPayant);
        $(".typeofpayclass__single-value").text(det[i].typeofpayment);
        $(".lensTypeDclass4__single-value").text(det[i].lensTypeDetails);
        $(".treatmeclass4__single-value").text(det[i].treatment);
        $(".typedevisionclass4__single-value").text(det[i].typeDeVision);
        $(".supplierclass4__single-value").text(det[i].supplier);
        $(".infoFrameclass4__single-value").text(det[i].frameType);
        $("#price4").val(det[i].price);
        //$(".tinttyclass4__single-value").text(det[i].tinttype);
      }
      if (det[i].infoOrderPair == null) {
        setSelectedPanier(det[i].lensShape);
        document.getElementById("rsph").value = det[i].recoOpticianRightSPH;
        document.getElementById("rcyl").value = det[i].recoOpticianRightCYL;
        document.getElementById("raxe").value = det[i].recoOpticianRightAXIS;
        document.getElementById("radd").value = det[i].recoOpticianRightADD;
        document.getElementById("rpd").value = det[i].recoOpticianRightPD;
        document.getElementById("lsph").value = det[i].recoOpticianLeftSPH;
        document.getElementById("lcyl").value = det[i].recoOpticianLeftCYL;
        document.getElementById("laxe").value = det[i].recoOpticianLeftAXIS;
        document.getElementById("ladd").value = det[i].recoOpticianLeftADD;
        document.getElementById("lpd").value = det[i].recoOpticianLeftPD;
        $(".crshclass__single-value").text(det[i].orderRightSPH);
        $(".crcyclass__single-value").text(det[i].orderRightCYL);
        $(".craxclass__single-value").text(det[i].orderRightAXIS);
        $(".cradclass__single-value").text(det[i].orderRightADD);
        $(".crpdclass__single-value").text(det[i].orderRightPD);
        $(".clshclass__single-value").text(det[i].orderLeftSPH);
        $(".clcyclass__single-value").text(det[i].orderLeftCYL);
        $(".claxclass__single-value").text(det[i].orderLeftAXIS);
        $(".cladclass__single-value").text(det[i].orderLeftADD);
        $(".clpdclass__single-value").text(det[i].orderLeftPD);
        document.getElementById("com1").value = det[i].comments;
        document.getElementById("ocom1").value = det[i].otherComments;
        document.getElementById("Heier").value = det[i].rightHeightEye;
        document.getElementById("Heiel").value = det[i].leftHeightEye;
        $(".montureclass__single-value").text(det[i].frameReference);
        localStorage.setItem("ordernumber", det[i].orderNumber);
        $(".frameCoclass__single-value").text(det[i].frameColor);
        $(".tiersPayaclass__single-value").text(det[i].tiersPayant);
        $(".typeofpayclass__single-value").text(det[i].typeofpayment);
        $(".lensTypeDclass__single-value").text(det[i].lensTypeDetails);
        $(".lensTypclass__single-value").text(det[i].lensType);
        $(".treatmeclass__single-value").text(det[i].treatment);
        //$(".tinttyclass__single-value").text(det[i].tinttype);
        $(".typedeverreclass__single-value").text(det[i].orderType);
        $(".typedeteinteclass__single-value").text(det[i].orderTint);
        $(".indiceclass__single-value").text(det[i].orderIndex);
        $(".typedevisionclass__single-value").text(det[i].typeDeVision);
        $(".supplierclass__single-value").text(det[i].supplier);
        $(".infoFrameclass__single-value").text(det[i].frameType);
        document.getElementById("price").value = det[i].price;
      }
    }

    setState((prevState) => {
      return { ...prevState, testname: det[0].testName };
    });

    setState((prevState) => {
      return { ...prevState, patientemailid: det[0].patientEmail };
    });

    setState((prevState) => {
      return { ...prevState, opticianname: det[0].opticianName };
    });

    setState((prevState) => {
      return { ...prevState, posname: det[0].posName };
    });

    setState((prevState) => {
      return { ...prevState, posemailid: det[0].posEmail };
    });

    setState((prevState) => {
      return { ...prevState, opticianemailid: det[0].opticianEmail };
    });
  };
  const add = () => {
    setIsEdit(false);
    setState((prevState) => {
      return { ...prevState, count: 1 };
    });
    setSelectedTypedeverreA1(null);
    setSelectedTypedeverreA2(null);
    setSelectedTypedeverreA3(null);
    setSelectedTypedeverreA4(null);
    setSelectedMontureA1(null);
    setSelectedMontureA2(null);
    setSelectedMontureA3(null);
    setSelectedMontureA4(null);
    setSelectedTreatmentA1(null);
    setSelectedTreatmentA2(null);
    setSelectedTreatmentA3(null);
    setSelectedTreatmentA4(null);
    setSelectedcolorA1(null);
    setSelectedcolorA2(null);
    setSelectedcolorA3(null);
    setSelectedcolorA4(null);
    setSelectedIndiceA1(null);
    setSelectedIndiceA2(null);
    setSelectedIndiceA3(null);
    setSelectedIndiceA4(null);
    setSelectedLensTypeDetailsA1(null);
    setSelectedLensTypeDetailsA2(null);
    setSelectedLensTypeDetailsA3(null);
    setSelectedLensTypeDetailsA4(null);
    setPrice(0.00);
    setPrice2(0.00);
    setPrice3(0.00);
    setPrice4(0.00);
    settotalprice(0.00)
    $(".frameCoclass__single-value").text("");
    $(".tiersPayaclass__single-value").text("");
    $(".typeofpayclass__single-value").text("");
    $(".lensTypeDclass__single-value").text("");
    $(".treatmeclass__single-value").text("");
    $(".typedeverreclass__single-value").text("");
    $(".typedeteinteclass__single-value").text("");
    $(".indiceclass__single-value").text("");
    $(".lensShapeclass__single-value").text("");
    $(".frameCoclass1__single-value").text("");
    $(".typeofpayclass1__single-value").text("");
    $(".lensTypeDclass1__single-value").text("");
    $(".treatmeclass1__single-value").text("");
    $(".typedeverreclass2__single-value").text("");
    $(".typedeteinteclass2__single-value").text("");
    $(".indiceclass2__single-value").text("");
    $(".lensShapeclass__single-value").text("");
    $(".frameCoclass3__single-value").text("");
    $(".typeofpayclass3__single-value").text("");
    $(".lensTypeDclass3__single-value").text("");
    $(".treatmeclass3__single-value").text("");
    $(".typedeverreclass3__single-value").text("");
    $(".typedeteinteclass3__single-value").text("");
    $(".indiceclass3__single-value").text("");
    $(".lensShapeclass__single-value").text("");
    $(".frameCoclass4__single-value").text("");
    $(".typeofpayclass4__single-value").text("");
    $(".lensTypeDclass4__single-value").text("");
    $(".treatmeclass4__single-value").text("");
    $(".typedeverreclass4__single-value").text("");
    $(".typedeteinteclass4__single-value").text("");
    $(".indiceclass4__single-value").text("");
    $(".lensShapeclass__single-value").text("");
    $(".typedevisionclass__single-value").text("");
    $(".typedevisionclass2__single-value").text("");
    $(".typedevisionclass3__single-value").text("");
    $(".typedevisionclass4__single-value").text("");
    $(".montureclass__single-value").text("");
    $(".montureclass2__single-value").text("");
    $(".montureclass3__single-value").text("");
    $(".montureclass4__single-value").text("");
    $(".supplierclass__single-value").text("");
    $(".supplierclass2__single-value").text("");
    $(".supplierclass3__single-value").text("");
    $(".supplierclass4__single-value").text("");
    $(".infoFrameclass__single-value").text("");
    $(".infoFrameclass2__single-value").text("");
    $(".infoFrameclass3__single-value").text("");
    $(".infoFrameclass4__single-value").text("");
    prereset();
    $(".crshclass__single-value").text(det.orderRightSPH);
    $(".crcyclass__single-value").text(det.orderRightCYL);
    $(".craxclass__single-value").text(det.orderRightAXIS);
    $(".cradclass__single-value").text(det.orderRightADD);
    $(".crpdclass__single-value").text(det.orderRightPD);
    $(".clshclass__single-value").text(det.orderLeftSPH);
    $(".clcyclass__single-value").text(det.orderLeftCYL);
    $(".claxclass__single-value").text(det.orderLeftAXIS);
    $(".cladclass__single-value").text(det.orderLeftADD);
    $(".clpdclass__single-value").text(det.orderLeftPD);
  };
  const prereset = () => {
    // document.getElementById("email").value = "";
    localStorage.setItem("ordernumber", "");
    localStorage.setItem("ordernumber1", "");
    localStorage.setItem("ordernumber2", "");
    localStorage.setItem("ordernumber3", "");
    $("#Heier,#Heiel,#price,#com1,#ocom1").val("");
    $("#Heier1,#Heiel1,#price1,#com21,#ocom21").val("");
    $("#Heier3,#Heiel3,#price3,#com23,#ocom23").val("");
    $("#Heier4,#Heiel4,#price4,#com24,#ocom24").val("");

    $(".checkbox").prop("checked", false);
    document.getElementById("pp2").setAttribute("hidden", "true");
    document.getElementById("pp3").setAttribute("hidden", "true");
    document.getElementById("pp4").setAttribute("hidden", "true");
    $("#validationRadio26").prop("checked", true);
    $("#validationRadio260").prop("checked", true);
    $("#validationRadio261").prop("checked", true);
  };
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "eyetestdetails__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 5);
  };
  const exportToExcel = async (status) => {
    const logourl = `${API_URL}/Orders/Export?status=${status}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    };

    try {
      const response = await fetch(logourl, requestOptions);
      const json = await response.json();

      const ws = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Order");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Order_Data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };
  const back = () => {
    var Orderdashboard = localStorage.getItem("Orderdashboard");
    if (Orderdashboard != "") {
      window.location.href = "/dashboard";
    } else {
      ReactDOM.render(
        <Eyetestresult status={""} heading={"Tous les tests de vue"} />,
        document.getElementById("c11")
      );
    }
  };
  function formatDate(dateString) {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Get day, month, and year
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1; // Add 1 since getMonth() returns zero-based index
    const year = date.getFullYear();

    // Convert month index to month abbreviation
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const monthAbbreviation = months[monthIndex - 1]; // Subtract 1 to match array index

    // Format day and month to have leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    // Return the formatted date string
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const from1 = (
    <div class="modal modal-slide-in fade" id="modals-slide-in">
      <div class="modal-dialog sidebar-sm w-100">
        <form class="add-new-record modal-content pt-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">
              {window.$language.AddNewRecord}
            </h5>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="card-body">
              {props.vv == 1 ? (
                <form class="dt_adv_search" method="POST">
                  <div class="col">
                    <div class="form-row mb-1">
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.TestName}:
                        </label>
                        <input
                          type="text"
                          value={state.testname}
                          class="form-control dt-full-name testnameclass"
                          id="testname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.Optician}:
                        </label>
                        <input
                          type="text"
                          value={state.opticianname}
                          class="form-control dt-full-name optnameclass"
                          id="optname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.POS}:
                        </label>
                        <input
                          type="text"
                          value={state.posname}
                          class="form-control dt-full-name posnameclass"
                          id="posname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.PatientEmail}
                        </label>
                        <input
                          type="text"
                          value={state.patientemailid}
                          class="form-control dt-full-name patientemailclass"
                          id="patientemail"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <form class="dt_adv_search" method="POST">
                  <div class="col">
                    <div class="form-row mb-1">
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.TestName}:
                        </label>
                        <input
                          type="text"
                          value={
                            isEdit == false
                              ? props.det.testName
                              : state.testname
                          }
                          class="form-control dt-full-name testnameclass"
                          id="testname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.Optician}:
                        </label>
                        <input
                          type="text"
                          value={
                            isEdit == false
                              ? props.det.opticianName
                              : state.opticianname
                          }
                          class="form-control dt-full-name optnameclass"
                          id="optname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.POS}:
                        </label>
                        <input
                          type="text"
                          value={
                            isEdit == false ? props.det.posName : state.posname
                          }
                          class="form-control dt-full-name posnameclass"
                          id="posname"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.PatientEmail}
                        </label>
                        <input
                          type="text"
                          value={
                            isEdit == false
                              ? props.det.patientEmail
                              : state.patientemailid
                          }
                          class="form-control dt-full-name patientemailclass"
                          id="patientemail"
                          placeholder=""
                          aria-label=""
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <hr class="my-0" />
            <div class="card-datatable">
              {props.vv == 1 ? (
                <table class="table table-striped  w-100">
                  <thead>
                    <tr>
                      <th class="border border-primary">
                        {window.$language.TYPEOFTEST}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.RIGHTEYE}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.LEFTEYE}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                    </tr>
                    <tr>
                      <td class="p-1">Reco opticien</td>

                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianRightSPH}
                          readOnly
                          id="rsph"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianRightCYL}
                          readOnly
                          id="rcyl"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianRightAXIS}
                          readOnly
                          id="raxe"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianRightADD}
                          readOnly
                          id="radd"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianRightPD}
                          readOnly
                          id="rpd"
                        />
                      </td>

                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianLeftSPH}
                          readOnly
                          id="lsph"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianLeftCYL}
                          readOnly
                          id="lcyl"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianLeftAXIS}
                          readOnly
                          id="laxe"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianLeftADD}
                          readOnly
                          id="ladd"
                        />
                      </td>
                      <td class="p-1">
                        <input
                          class="form-control px-0"
                          defaultValue={state.recoOpticianLeftPD}
                          readOnly
                          id="lpd"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="p-1">Commande</td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightSPH,
                            value: state.orderRightSPH,
                          }}
                          id="crsh"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crshclass"}
                          classNamePrefix={"eyetestdetails crshclass"}
                          options={options}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedCrsh: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightCYL,
                            value: state.orderRightCYL,
                          }}
                          id="crcy"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crcyclass"}
                          classNamePrefix={"eyetestdetails crcyclass"}
                          options={options2}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedCrcy: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightAXIS,
                            value: state.orderRightAXIS,
                          }}
                          id="crax"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails craxclass"}
                          classNamePrefix={"eyetestdetails craxclass"}
                          options={options3}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedCrax: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightADD,
                            value: state.orderRightADD,
                          }}
                          id="crad"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails cradclass"}
                          classNamePrefix={"eyetestdetails cradclass"}
                          options={options5}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedCrad: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>

                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightPD,
                            value: state.orderRightPD,
                          }}
                          id="crpd"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crpdclass"}
                          classNamePrefix={"eyetestdetails crpdclass"}
                          options={options4}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedCrpd: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftSPH,
                            value: state.orderLeftSPH,
                          }}
                          id="clsh"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clshclass"}
                          classNamePrefix={"eyetestdetails clshclass"}
                          options={options}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedClsh: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftCYL,
                            value: state.orderLeftCYL,
                          }}
                          id="clcy"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clcyclass"}
                          classNamePrefix={"eyetestdetails clcyclass"}
                          options={options2}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedClcy: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftAXIS,
                            value: state.orderLeftAXIS,
                          }}
                          id="clax"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails claxclass"}
                          classNamePrefix={"eyetestdetails claxclass"}
                          options={options3}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedClax: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftADD,
                            value: state.orderLeftADD,
                          }}
                          id="clad"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails cladclass"}
                          classNamePrefix={"eyetestdetails cladclass"}
                          options={options5}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedClad: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftPD,
                            value: state.orderLeftPD,
                          }}
                          id="clpd"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clpdclass"}
                          classNamePrefix={"eyetestdetails clpdclass"}
                          options={options4}
                          // onChange={(selectedOption) =>
                          //   setState({
                          //     selectedClpd: selectedOption,
                          //   })
                          // }
                          placeholder="0.00"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              ) : (
                <table class="table table-striped  w-100">
                  <thead>
                    {props.det.isContactLensType == false ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    {props.det.isContactLensType == true ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                    </tr>
                    {props.det.status == "Approved" ? (
                      <>
                        <tr>
                          <td class="p-1">Prescription finale</td>

                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.sph}
                                      readOnly
                                      id="rsph"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.cyl}
                                      readOnly
                                      id="rcyl"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.axe}
                                      readOnly
                                      id="raxe"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.add}
                                      readOnly
                                      id="radd"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.pd}
                                      readOnly
                                      id="rpd"
                                    />
                                  </td>
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.sph}
                                      readOnly
                                      id="lsph"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.cyl}
                                      readOnly
                                      id="lcyl"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.axe}
                                      readOnly
                                      id="laxe"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.add}
                                      readOnly
                                      id="ladd"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.pd}
                                      readOnly
                                      id="lpd"
                                    />
                                  </td>
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="crsh"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crshclass"}
                                      classNamePrefix={
                                        "eyetestdetails crshclass"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrsh: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcyclass"}
                                      classNamePrefix={
                                        "eyetestdetails crcyclass"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrcy: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails craxclass"}
                                      classNamePrefix={
                                        "eyetestdetails craxclass"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrax: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails cradclass"}
                                      classNamePrefix={
                                        "eyetestdetails cradclass"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrad: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpdclass"}
                                        classNamePrefix={
                                          "eyetestdetails crpdclass"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //   setState({
                                        //     selectedCrpd: selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
                        <input
                          id="crph"
                          class="form-control form-control-sm p-1"
                          defaultValue={l.phigh}
                        />
                      </td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clshclass"}
                                      classNamePrefix={
                                        "eyetestdetails clshclass"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClsh: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcyclass"}
                                      classNamePrefix={
                                        "eyetestdetails clcyclass"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClcy: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails claxclass"}
                                      classNamePrefix={
                                        "eyetestdetails claxclass"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClax: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails cladclass"}
                                      classNamePrefix={
                                        "eyetestdetails cladclass"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClad: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpdclass"}
                                        classNamePrefix={
                                          "eyetestdetails clpdclass"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //   setState({
                                        //     selectedClpd: selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
                        <input
                          id="clph"
                          class="form-control form-control-sm p-1"
                          defaultValue={l.phigh}
                        />
                      </td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td class="p-1">Reco opticien</td>

                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.sph}
                                      readOnly
                                      id="rsph"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.cyl}
                                      readOnly
                                      id="rcyl"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.axe}
                                      readOnly
                                      id="raxe"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.add}
                                      readOnly
                                      id="radd"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.pd}
                                      readOnly
                                      id="rpd"
                                    />
                                  </td>
                                  {/* <td class="p-1 d-none" id="rphigh">
                        {l.phigh}
                      </td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.sph}
                                      readOnly
                                      id="lsph"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.cyl}
                                      readOnly
                                      id="lcyl"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.axe}
                                      readOnly
                                      id="laxe"
                                    />
                                  </td>
                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.add}
                                      readOnly
                                      id="ladd"
                                    />
                                  </td>

                                  <td class="p-1">
                                    <input
                                      class="form-control px-0"
                                      defaultValue={l.pd}
                                      readOnly
                                      id="lpd"
                                    />
                                  </td>
                                  {/* <td class="p-1 d-none" id="lphigh">
                        {l.phigh}
                      </td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="crsh"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crshclass"}
                                      classNamePrefix={
                                        "eyetestdetails crshclass"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrsh: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcyclass"}
                                      classNamePrefix={
                                        "eyetestdetails crcyclass"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrcy: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails craxclass"}
                                      classNamePrefix={
                                        "eyetestdetails craxclass"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrax: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails cradclass"}
                                      classNamePrefix={
                                        "eyetestdetails cradclass"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedCrad: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpdclass"}
                                        classNamePrefix={
                                          "eyetestdetails crpdclass"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //   setState({
                                        //     selectedCrpd: selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
                        <input
                          id="crph"
                          class="form-control form-control-sm p-1"
                          defaultValue={l.phigh}
                        />
                      </td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clshclass"}
                                      classNamePrefix={
                                        "eyetestdetails clshclass"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClsh: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcyclass"}
                                      classNamePrefix={
                                        "eyetestdetails clcyclass"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClcy: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails claxclass"}
                                      classNamePrefix={
                                        "eyetestdetails claxclass"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClax: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails cladclass"}
                                      classNamePrefix={
                                        "eyetestdetails cladclass"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //   setState({
                                      //     selectedClad: selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpdclass"}
                                        classNamePrefix={
                                          "eyetestdetails clpdclass"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //   setState({
                                        //     selectedClpd: selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
                        <input
                          id="clph"
                          class="form-control form-control-sm p-1"
                          defaultValue={l.phigh}
                        />
                      </td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    )}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              )}
            </div>
            <hr class="mb-2" />
            <div class="form-row">
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de Panier*
                  </label>
                  <div class="select-container8">
                    <Select
                      class="form-control"
                      id="lenstype"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onChange={handlePanierChange}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails lensShapeclass"}
                      classNamePrefix={"eyetestdetails lensShapeclass"}
                      options={Typedepanier}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Tiers Payant*
                  </label>
                  <div class="select-container8">
                    <Select
                      class="form-control"
                      id="tierspayant"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails tiersPayaclass"}
                      classNamePrefix={"eyetestdetails tiersPayaclass"}
                      options={TiersPayant}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de paiement*
                  </label>
                  <div class="select-container8">
                    <Select
                      class="form-control"
                      id="typeofpayment"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails typeofpayclass"}
                      classNamePrefix={"eyetestdetails typeofpayclass"}
                      options={Typeofpayment}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
            </div>
            <h4>Paire-1</h4>
            <div class="form-row">
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de vision*
                  </label>
                  <div class="select-container2">
                    <Select
                      class="form-control"
                      id="typedevision"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      //onChange={(e)=>setTypedevision1(e.value)}
                      className={"eyetestdetails typedevisionclass"}
                      classNamePrefix={"eyetestdetails typedevisionclass"}
                      options={Typedevision}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de verre*
                  </label>
                  <div class="select-container2">
                    <Select
                      class="form-control"
                      id="typedeverre"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails typedeverreclass"}
                      classNamePrefix={"eyetestdetails typedeverreclass"}
                      options={
                        selectedPanier == "A" || selectedPanier == "Mixte Verre A" ? TypedeverreA1 :
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A"
                          ? TypedeverreB1
                          : selectedPanier == "Sans Panier"
                          ? TypedeverreSP1
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedTypedeverreA1}
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Indice*
                  </label>
                  <div class="select-container1">
                    <Select
                      class="form-control"
                      id="indice"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails indiceclass"}
                      classNamePrefix={"eyetestdetails indiceclass"}
                      options={
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A" || selectedPanier == "Sans Panier" ? Indiceothers :
                        selectedPanier == "A" ||
                        selectedPanier == "Mixte Verre A"
                          ? IndiceA1
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedIndiceA1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Couleur
                  </label>
                  <div class="select-container3">
                    <Select
                      class="form-control"
                      id="color"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails frameCoclass"}
                      classNamePrefix={"eyetestdetails frameCoclass"}
                      options={
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A" || selectedPanier == "Sans Panier" ? colorA34:
                        selectedPanier == "A" ||
                        selectedPanier == "Mixte Verre A"
                          ? colorA1
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedcolorA1}
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Option de verre
                  </label>
                  <div class="select-container3">
                    <Select
                      class="form-control"
                      id="lenstypedetails"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails lensTypeDclasss"}
                      classNamePrefix={"eyetestdetails lensTypeDclass"}
                      options={
                        selectedPanier == "A" ||
                        selectedPanier == "Mixte Verre A" ? LensTypeDetailsA1 :
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A"
                          ? LensTypeDetailsB2
                          : selectedPanier == "Sans Panier"
                          ? LensTypeDetailsB34
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedLensTypeDetailsA1}
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Traitement*
                  </label>
                  <div class="select-container3">
                    <Select
                      class="form-control"
                      id="treatment"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails treatmeclass"}
                      classNamePrefix={"eyetestdetails treatmeclass"}
                      options={
                        selectedPanier == "A" ||
                        selectedPanier == "Mixte Verre A" ? TreatmentA1 :
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A"
                          ? Treatment234
                          : selectedPanier == "Sans Panier"
                          ? TreatmentSP3
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedTreatmentA1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de monture*
                  </label>
                  <div class="select-container4">
                    <Select
                      class="form-control"
                      id="frameType"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails infoFrameclass"}
                      classNamePrefix={"eyetestdetails infoFrameclass"}
                      options={ selectedPanier == "A" ||
                        selectedPanier == "Mixte Verre A" ||
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Monture A"
                          || selectedPanier == "Sans Panier" ? frameTypeA1 : Nooption}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Monture*
                  </label>
                  <div class="select-container5">
                    <Select
                      class="form-control"
                      id="monture"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails montureclass"}
                      classNamePrefix={"eyetestdetails montureclass"}
                      options={
                        selectedPanier == "A" ||
                        selectedPanier == "Mixte Monture A" || selectedPanier == "Sans Panier" ? MontureA1 :
                        selectedPanier == "B" ||
                        selectedPanier == "Mixte Verre A"
                          ? MontureB1
                          : Nooption
                      }
                      placeholder="Saisir une option"
                      onChange={setSelectedMontureA1}
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Fournisseur*
                  </label>
                  <div class="select-container4">
                    <Select
                      class="form-control"
                      id="supplier"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails supplierclass"}
                      classNamePrefix={"eyetestdetails supplierclass"}
                      options={Supplier}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 ">
                <label>Prix</label>
                <input
                  type="text"
                  id="price"
                  class="form-control flatpickr-basic"
                  value={price.toFixed(2) + " €"}
                  placeholder=""
                  disabled={isEdit== true}
                />
              </div>
              <div class="col-lg-6  ">
                <label>{window.$language.Commentsontheorder}</label>
                <input
                  type="text"
                  id="com1"
                  class="form-control flatpickr-basic"
                  placeholder=""
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-4 d-none">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label" for="login-email">
                    Type de teinte:
                  </label>
                  <div class="select-container2">
                    <Select
                      class="form-control"
                      id="typedeteinte"
                      defaultValue={{
                        label: state.orderLeftPD,
                        value: state.orderLeftPD,
                      }}
                      onMenuOpen={onMenuOpen}
                      className={"eyetestdetails typedeteinteclass"}
                      classNamePrefix={"eyetestdetails typedeteinteclass"}
                      options={Typedeteinte}
                      placeholder="Saisir une option"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-none ">
                <label>{window.$language.OtherComments}</label>
                <input
                  type="text"
                  id="ocom1"
                  class="form-control flatpickr-basic"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-4 mt-1">
              <div class="form-group">
                <label class="d-block ">Statut de la commande :</label>
                <div class="custom-control custom-radio col">
                  <input
                    type="radio"
                    id="validatRadio22"
                    name="status1"
                    value="Pending"
                    class="custom-control-input"
                    defaultChecked
                  />
                  <label class="custom-control-label" for="validatRadio22">
                    {window.$language.Pending}
                  </label>
                </div>
                <div class="custom-control custom-radio col">
                  <input
                    type="radio"
                    id="validatRadio23"
                    name="status1"
                    // value="ToBeOrdered"
                    value="SAV"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="validatRadio23">
                    SAV
                  </label>
                </div>
              </div>
            </div>
            <hr class="mb-2" />

            <div class="col-lg-4 mr-4 pairclass ">
              <div class="form-group">
                <label class="d-block">
                  {window.$language.NeedsaSecondpair} ?{" "}
                </label>
                <div class="custom-control custom-radio my-50">
                  <input
                    type="radio"
                    onClick={(data) => showp2(data.target.value)}
                    id="validationRadio17"
                    name="Secondpair"
                    value="yes"
                    class="custom-control-input"
                    disabled={isEdit == true}
                  />
                  <label class="custom-control-label" for="validationRadio17">
                    {window.$language.Yes}
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    onClick={(data) => showp2(data.target.value)}
                    type="radio"
                    id="validationRadio26"
                    name="Secondpair"
                    value="no"
                    disabled={isEdit == true}
                    class="custom-control-input"
                    defaultChecked
                  />
                  <label class="custom-control-label" for="validationRadio26">
                    {window.$language.No}
                  </label>
                </div>
              </div>
            </div>
            <div id="pp2">
              <h4>Paire-2</h4>
              {props.vv == 1 ? (
                <table class="table table-striped  w-100">
                  <thead>
                    <tr>
                      <th class="border border-primary">
                        {window.$language.TYPEOFTEST}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.RIGHTEYE}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.LEFTEYE}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                    </tr>
                    <tr>
                      <td class="p-1">Commande</td>
                      <td class="p-1 select-container">
                        <Select
                          defaultValue={{
                            label: state.orderRightSPH,
                            value: state.orderRightSPH,
                          }}
                          class="select-container"
                          id="crsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crsh1class"}
                          classNamePrefix={"eyetestdetails crsh1class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightCYL,
                            value: state.orderRightCYL,
                          }}
                          id="crcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crcy1class"}
                          classNamePrefix={"eyetestdetails crcy1class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightAXIS,
                            value: state.orderRightAXIS,
                          }}
                          id="crax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crax1class"}
                          classNamePrefix={"eyetestdetails crax1class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightADD,
                            value: state.orderRightADD,
                          }}
                          id="crad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crad1class"}
                          classNamePrefix={"eyetestdetails crad1class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightPD,
                            value: state.orderRightPD,
                          }}
                          id="crpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crpd1class"}
                          classNamePrefix={"eyetestdetails crpd1class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftSPH,
                            value: state.orderLeftSPH,
                          }}
                          id="clsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clsh1class"}
                          classNamePrefix={"eyetestdetails clsh1class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftCYL,
                            value: state.orderLeftCYL,
                          }}
                          id="clcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clcy1class"}
                          classNamePrefix={"eyetestdetails clcy1class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftAXIS,
                            value: state.orderLeftAXIS,
                          }}
                          id="clax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clax1class"}
                          classNamePrefix={"eyetestdetails clax1class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftADD,
                            value: state.orderLeftADD,
                          }}
                          id="clad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clad1class"}
                          classNamePrefix={"eyetestdetails clad1class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftPD,
                            value: state.orderLeftPD,
                          }}
                          id="clpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clpd1class"}
                          classNamePrefix={"eyetestdetails clpd1class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>{" "}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier1"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel1"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              ) : (
                <table class="table table-striped  w-100">
                  <thead>
                    {props.det.isContactLensType == false ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    {props.det.isContactLensType == true ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                    </tr>
                    {props.det.status == "Approved" ? (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      class="select-container"
                                      id="crsh1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh1class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh1class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy1class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy1class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax1class"}
                                      classNamePrefix={
                                        "eyetestdetails crax1class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad1class"}
                                      classNamePrefix={
                                        "eyetestdetails crad1class"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd1"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd1class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd1class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh1class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh1class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy1class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy1class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax1class"}
                                      classNamePrefix={
                                        "eyetestdetails clax1class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad1class"}
                                      classNamePrefix={
                                        "eyetestdetails clad1class"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd1"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd1class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd1class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="clph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="crsh1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh1class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh1class"
                                      }
                                      options={options}
                                      // onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy1class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy1class"
                                      }
                                      options={options2}
                                      // onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax1class"}
                                      classNamePrefix={
                                        "eyetestdetails crax1class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad1class"}
                                      classNamePrefix={
                                        "eyetestdetails crad1class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd1"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd1class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd1class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1">
                                      <input
                                        id="crpd1"
                                        class="form-control form-control-sm p-1"
                                        defaultValue="0"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}
                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh1class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh1class"
                                      }
                                      options={options}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy1class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy1class"
                                      }
                                      options={options2}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax1class"}
                                      classNamePrefix={
                                        "eyetestdetails clax1class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad1"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad1class"}
                                      classNamePrefix={
                                        "eyetestdetails clad1class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd1"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd1class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd1class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        defaultValue="0"
                                        id="clpd1"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd1class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd1class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="clph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    )}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier1"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel1"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              )}
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de vision*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedevision2"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        // onChange={(e)=>setTypedevision2(e.value)}
                        className={"eyetestdetails typedevisionclass2"}
                        classNamePrefix={"eyetestdetails typedevisionclass2"}
                        options={Typedevision}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de verre*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedeverre2"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails typedeverreclass2"}
                        classNamePrefix={"eyetestdetails typedeverreclass2"}
                        options={
                          selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ? TypedeverreA2 :
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            ? TypedeverreB2
                            : selectedPanier == "Sans Panier"
                            ? TypedeverreSP2
                            : Nooption
                          }
                        placeholder="Saisir une option"
                        onChange={setSelectedTypedeverreA2}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Indice*
                    </label>
                    <div class="select-container1">
                      <Select
                        class="form-control"
                        id="indice"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails indiceclass2"}
                        classNamePrefix={"eyetestdetails indiceclass2"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? Indiceothers : Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedIndiceA2}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Couleur
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="color"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails frameCoclass1"}
                        classNamePrefix={"eyetestdetails frameCoclass1"}
                        options={
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A" || selectedPanier == "Sans Panier" ? colorA34 :
                          selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A"
                            ? colorA2
                            : Nooption
                        }
                        placeholder="Saisir une option"
                        onChange={setSelectedcolorA2}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Option de verre
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="lenstypedetails"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails lensTypeDclasss1"}
                        classNamePrefix={"eyetestdetails lensTypeDclass1"}
                        options={
                          selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" || selectedPanier == "Sans Panier"? LensTypeDetailsB34 :
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            ? LensTypeDetailsB2
                            : Nooption
                        }
                        placeholder="Saisir une option"
                        onChange={setSelectedLensTypeDetailsA2}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Traitement*
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="treatment"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails treatmeclass1"}
                        classNamePrefix={"eyetestdetails treatmeclass1"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? TreatmentSP3 : Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedTreatmentA2}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de monture*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="frameType2"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails infoFrameclass2"}
                        classNamePrefix={"eyetestdetails infoFrameclass2"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? frameTypeA1 : Nooption}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Monture*
                    </label>
                    <div class="select-container5">
                      <Select
                        class="form-control"
                        id="monture2"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails montureclass2"}
                        classNamePrefix={"eyetestdetails montureclass2"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? Monture2 : Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedMontureA2}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Fournisseur*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="supplier2"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails supplierclass2"}
                        classNamePrefix={"eyetestdetails supplierclass2"}
                        options={Supplier}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6  ">
                  <label>Prix</label>
                  <input
                    type="text"
                    id="price1"
                    value={price2.toFixed(2) + " €"}
                    disabled={isEdit== true}
                    class="form-control flatpickr-basic price"
                    placeholder=""
                  />
                </div>
                <div class="col-lg-6  ">
                  <label>{window.$language.Commentsontheorder}</label>
                  <input
                    type="text"
                    id="com21"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6 d-none ">
                  <label>{window.$language.OtherComments}</label>
                  <input
                    type="text"
                    id="ocom21"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
                <div class="col-lg-4 d-none">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de teinte:
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedeteinte"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails typedeteinteclass2"}
                        classNamePrefix={"eyetestdetails typedeteinteclass2"}
                        options={Typedeteinte}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mt-1">
                <div class="form-group ">
                  <label class="d-block ">Statut de la commande:</label>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio22"
                      name="status2"
                      value="Pending"
                      class="custom-control-input"
                      defaultChecked
                    />
                    <label class="custom-control-label" for="validationRadio22">
                      {window.$language.Pending}
                    </label>
                  </div>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio23"
                      name="status2"
                      // value="ToBeOrdered"
                      value="SAV"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="validationRadio23">
                      SAV
                    </label>
                  </div>
                </div>
              </div>
              <hr class="mb-2" />
              <div class="col-lg-4 mr-4 pairclass ">
                <div class="form-group">
                  <label class="d-block">Besoin d'une troisième paire? </label>
                  <div class="custom-control custom-radio my-50">
                    <input
                      type="radio"
                      onClick={(data) => showp3(data.target.value)}
                      id="validationRadio178"
                      name="thirdpair"
                      value="yes"
                      class="custom-control-input"
                      disabled={isEdit == true}
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio178"
                    >
                      {window.$language.Yes}
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      onClick={(data) => showp3(data.target.value)}
                      type="radio"
                      id="validationRadio260"
                      name="thirdpair"
                      value="no"
                      class="custom-control-input"
                      defaultChecked
                      disabled={isEdit == true}
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio260"
                    >
                      {window.$language.No}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div id="pp3">
              <h4>Paire-3</h4>
              {props.vv == 1 ? (
                <table class="table table-striped  w-100">
                  <thead>
                    <tr>
                      <th class="border border-primary">
                        {window.$language.TYPEOFTEST}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.RIGHTEYE}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.LEFTEYE}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                    </tr>
                    <tr>
                      <td class="p-1">Commande</td>
                      <td class="p-1 select-container">
                        <Select
                          defaultValue={{
                            label: state.orderRightSPH,
                            value: state.orderRightSPH,
                          }}
                          class="select-container"
                          id="crsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crsh2class"}
                          classNamePrefix={"eyetestdetails crsh2class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightCYL,
                            value: state.orderRightCYL,
                          }}
                          id="crcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crcy2class"}
                          classNamePrefix={"eyetestdetails crcy2class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightAXIS,
                            value: state.orderRightAXIS,
                          }}
                          id="crax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crax2class"}
                          classNamePrefix={"eyetestdetails crax2class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightADD,
                            value: state.orderRightADD,
                          }}
                          id="crad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crad2class"}
                          classNamePrefix={"eyetestdetails crad2class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightPD,
                            value: state.orderRightPD,
                          }}
                          id="crpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crpd2class"}
                          classNamePrefix={"eyetestdetails crpd2class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftSPH,
                            value: state.orderLeftSPH,
                          }}
                          id="clsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clsh2class"}
                          classNamePrefix={"eyetestdetails clsh2class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftCYL,
                            value: state.orderLeftCYL,
                          }}
                          id="clcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clcy2class"}
                          classNamePrefix={"eyetestdetails clcy2class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftAXIS,
                            value: state.orderLeftAXIS,
                          }}
                          id="clax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clax2class"}
                          classNamePrefix={"eyetestdetails clax2class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftADD,
                            value: state.orderLeftADD,
                          }}
                          id="clad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clad2class"}
                          classNamePrefix={"eyetestdetails clad2class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftPD,
                            value: state.orderLeftPD,
                          }}
                          id="clpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clpd2class"}
                          classNamePrefix={"eyetestdetails clpd2class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>{" "}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier3"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel3"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              ) : (
                <table class="table table-striped  w-100">
                  <thead>
                    {props.det.isContactLensType == false ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    {props.det.isContactLensType == true ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                    </tr>
                    {props.det.status == "Approved" ? (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      class="select-container"
                                      id="crsh2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh2class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh2class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy2class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy2class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax2class"}
                                      classNamePrefix={
                                        "eyetestdetails crax2class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad2class"}
                                      classNamePrefix={
                                        "eyetestdetails crad2class"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      // //  setState({
                                      // //     selectedOption,
                                      // //   })
                                      // // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd2"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd2class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd2class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh2class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh2class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy2class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy2class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax2class"}
                                      classNamePrefix={
                                        "eyetestdetails clax2class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad2class"}
                                      classNamePrefix={
                                        "eyetestdetails clad2class"
                                      }
                                      options={options5}
                                      onChange={(selectedOption) =>
                                        setState({
                                          selectedOption,
                                        })
                                      }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd2"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd2class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd2class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="clph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="crsh2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh2class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh2class"
                                      }
                                      options={options}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy2class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy2class"
                                      }
                                      options={options2}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax2class"}
                                      classNamePrefix={
                                        "eyetestdetails crax2class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad2class"}
                                      classNamePrefix={
                                        "eyetestdetails crad2class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd2"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd2class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd2class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1">
                                      <input
                                        id="crpd2"
                                        class="form-control form-control-sm p-1"
                                        defaultValue="0"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}
                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh2class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh2class"
                                      }
                                      options={options}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy2class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy2class"
                                      }
                                      options={options2}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax2class"}
                                      classNamePrefix={
                                        "eyetestdetails clax2class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad2"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad2class"}
                                      classNamePrefix={
                                        "eyetestdetails clad2class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd2"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd2class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd2class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        defaultValue="0"
                                        id="clpd2"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd2class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd2class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="clph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    )}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier3"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel3"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              )}

              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de vision*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedevision3"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        // onChange={(e)=>setTypedevision3(e.value)}
                        className={"eyetestdetails typedevisionclass3"}
                        classNamePrefix={"eyetestdetails typedevisionclass3"}
                        options={Typedevision}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de verre*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedeverre3"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails typedeverreclass3"}
                        classNamePrefix={"eyetestdetails typedeverreclass3"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? TypedeverreSP3 : Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedTypedeverreA3}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Indice*
                    </label>
                    <div class="select-container1">
                      <Select
                        class="form-control"
                        id="indice"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails indiceclass3"}
                        classNamePrefix={"eyetestdetails indiceclass3"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? Indiceothers:Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedIndiceA3}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Couleur
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="color"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails frameCoclass3"}
                        classNamePrefix={"eyetestdetails frameCoclass3"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? colorA34:Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedcolorA3}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Option de verre
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="lenstypedetails"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails lensTypeDclasss3"}
                        classNamePrefix={"eyetestdetails lensTypeDclass3"}
                        options={selectedPanier == "" ? "" :LensTypeDetailsB34}
                        placeholder="Saisir une option"
                        onChange={setSelectedLensTypeDetailsA3}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Traitement*
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="treatment"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails treatmeclass3"}
                        classNamePrefix={"eyetestdetails treatmeclass3"}
                        options={selectedPanier == "" ? "" :TreatmentSP3}
                        placeholder="Saisir une option"
                        onChange={setSelectedTreatmentA3}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de monture*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="frameType3"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails infoFrameclass3"}
                        classNamePrefix={"eyetestdetails infoFrameclass3"}
                        options={selectedPanier == "" ? "" :frameTypeA1}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Monture*
                    </label>
                    <div class="select-container5">
                      <Select
                        class="form-control"
                        id="monture3"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails montureclass3"}
                        classNamePrefix={"eyetestdetails montureclass3"}
                        options={selectedPanier == "" ? "" : Monture34}
                        placeholder="Saisir une option"
                        onChange={setSelectedMontureA3}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Fournisseur*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="supplier3"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails supplierclass3"}
                        classNamePrefix={"eyetestdetails supplierclass3"}
                        options={Supplier}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6  ">
                  <label>Prix</label>
                  <input
                    type="text"
                    id="price3"
                    class="form-control flatpickr-basic"
                    value={price3.toFixed(2) + " €"}
                    placeholder=""
                    disabled={isEdit== true}
                  />
                </div>
                <div class="col-lg-6  ">
                  <label>{window.$language.Commentsontheorder}</label>
                  <input
                    type="text"
                    id="com23"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6 d-none">
                  <label>{window.$language.OtherComments}</label>
                  <input
                    type="text"
                    id="ocom23"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-lg-4 mt-1">
                <div class="form-group ">
                  <label class="d-block ">Statut de la commande:</label>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio232"
                      name="status3"
                      value="Pending"
                      class="custom-control-input"
                      defaultChecked
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio232"
                    >
                      {window.$language.Pending}
                    </label>
                  </div>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio263"
                      name="status3"
                      // value="ToBeOrdered"
                      value="SAV"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio263"
                    >
                      SAV
                    </label>
                  </div>
                </div>
              </div>
              <hr class="mb-2" />
              <div class="col-lg-4 mr-4 pairclass ">
                <div class="form-group">
                  <label class="d-block">Besoin d'une quatrième paire ? </label>
                  <div class="custom-control custom-radio my-50">
                    <input
                      type="radio"
                      onClick={(data) => showp4(data.target.value)}
                      id="validationRadio179"
                      name="Forthpair"
                      value="yes"
                      class="custom-control-input"
                      disabled={isEdit == true}
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio179"
                    >
                      {window.$language.Yes}
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      onClick={(data) => showp4(data.target.value)}
                      type="radio"
                      id="validationRadio261"
                      name="Forthpair"
                      value="no"
                      class="custom-control-input"
                      defaultChecked
                      disabled={isEdit == true}
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio261"
                    >
                      {window.$language.No}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div id="pp4">
              <h4>Paire-4</h4>
              {props.vv == 1 ? (
                <table class="table table-striped  w-100">
                  <thead>
                    <tr>
                      <th class="border border-primary">
                        {window.$language.TYPEOFTEST}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.RIGHTEYE}
                      </th>
                      <th
                        class="border border-primary align-middle"
                        colSpan="5"
                      >
                        {window.$language.LEFTEYE}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      <th>PD*</th>
                      {/* <th>PHIGH</th> */}
                    </tr>
                    <tr>
                      <td class="p-1">Commande</td>
                      <td class="p-1 select-container">
                        <Select
                          defaultValue={{
                            label: state.orderRightSPH,
                            value: state.orderRightSPH,
                          }}
                          class="select-container"
                          id="crsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crsh3class"}
                          classNamePrefix={"eyetestdetails crsh3class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightCYL,
                            value: state.orderRightCYL,
                          }}
                          id="crcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crcy3class"}
                          classNamePrefix={"eyetestdetails crcy3class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightAXIS,
                            value: state.orderRightAXIS,
                          }}
                          id="crax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crax3class"}
                          classNamePrefix={"eyetestdetails crax3class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightADD,
                            value: state.orderRightADD,
                          }}
                          id="crad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crad3class"}
                          classNamePrefix={"eyetestdetails crad3class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderRightPD,
                            value: state.orderRightPD,
                          }}
                          id="crpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails crpd3class"}
                          classNamePrefix={"eyetestdetails crpd3class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftSPH,
                            value: state.orderLeftSPH,
                          }}
                          id="clsh1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clsh3class"}
                          classNamePrefix={"eyetestdetails clsh3class"}
                          options={options}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftCYL,
                            value: state.orderLeftCYL,
                          }}
                          id="clcy1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clcy3class"}
                          classNamePrefix={"eyetestdetails clcy3class"}
                          options={options2}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftAXIS,
                            value: state.orderLeftAXIS,
                          }}
                          id="clax1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clax3class"}
                          classNamePrefix={"eyetestdetails clax3class"}
                          options={options3}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftADD,
                            value: state.orderLeftADD,
                          }}
                          id="clad1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clad3class"}
                          classNamePrefix={"eyetestdetails clad3class"}
                          options={options5}
                          placeholder="0.00"
                        />
                      </td>
                      <td class="p-1 select-container">
                        <Select
                          class="select-container"
                          defaultValue={{
                            label: state.orderLeftPD,
                            value: state.orderLeftPD,
                          }}
                          id="clpd1"
                          onMenuOpen={onMenuOpen}
                          className={"eyetestdetails clpd3class"}
                          classNamePrefix={"eyetestdetails clpd3class"}
                          options={options4}
                          placeholder="0.00"
                        />
                      </td>
                    </tr>{" "}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier4"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel4"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              ) : (
                <table class="table table-striped  w-100">
                  <thead>
                    {props.det.isContactLensType == false ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    {props.det.isContactLensType == true ? (
                      <tr>
                        <th class="border border-primary">
                          {window.$language.TYPEOFTEST}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : null}
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {props.det.isContactLensType == false ? (
                        <th>PD*</th>
                      ) : null}
                      {/* <th>PHIGH</th> */}
                    </tr>
                    {props.det.status == "Approved" ? (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      class="select-container"
                                      id="crsh4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh3class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh3class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy3class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy3class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax3class"}
                                      classNamePrefix={
                                        "eyetestdetails crax3class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad3class"}
                                      classNamePrefix={
                                        "eyetestdetails crad3class"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd4"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd3class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd3class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh3class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh3class"
                                      }
                                      options={options}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy3class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy3class"
                                      }
                                      options={options2}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax3class"}
                                      classNamePrefix={
                                        "eyetestdetails clax3class"
                                      }
                                      options={options3}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad3class"}
                                      classNamePrefix={
                                        "eyetestdetails clad3class"
                                      }
                                      options={options5}
                                      // onChange={(selectedOption) =>
                                      //  setState({
                                      //     selectedOption,
                                      //   })
                                      // }
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd4"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd3class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd3class"
                                        }
                                        options={options4}
                                        // onChange={(selectedOption) =>
                                        //  setState({
                                        //     selectedOption,
                                        //   })
                                        // }
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}

                                  {/* <td class="p-1 d-none">
<input
id="clph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td class="p-1">Commande</td>
                          {props.det.testDetails.map((l) => (
                            <>
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "right" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="crsh4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crsh3class"}
                                      classNamePrefix={
                                        "eyetestdetails crsh3class"
                                      }
                                      options={options}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="crcy4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crcy3class"}
                                      classNamePrefix={
                                        "eyetestdetails crcy3class"
                                      }
                                      options={options2}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="crax4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crax3class"}
                                      classNamePrefix={
                                        "eyetestdetails crax3class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="crad4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails crad3class"}
                                      classNamePrefix={
                                        "eyetestdetails crad3class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        class="select-container"
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="crpd4"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails crpd3class"}
                                        classNamePrefix={
                                          "eyetestdetails crpd3class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1 select-container">
                                      <input
                                        id="crpd4"
                                        class="form-control form-control-sm p-1"
                                        defaultValue="0"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}
                                  {/* <td class="p-1 d-none">
<input
id="crph"
class="form-control form-control-sm p-1"
defaultValue={l.phigh}
/>
</td> */}
                                </>
                              ) : null}
                              {l.testType == "Reco opticien" &&
                              l.whichEye == "left" ? (
                                <>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.sph,
                                        value: l.sph,
                                      }}
                                      id="clsh4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clsh3class"}
                                      classNamePrefix={
                                        "eyetestdetails clsh3class"
                                      }
                                      options={options}
                                      // onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.cyl,
                                        value: l.cyl,
                                      }}
                                      id="clcy4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clcy3class"}
                                      classNamePrefix={
                                        "eyetestdetails clcy3class"
                                      }
                                      options={options2}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.axe,
                                        value: l.axe,
                                      }}
                                      id="clax4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clax3class"}
                                      classNamePrefix={
                                        "eyetestdetails clax3class"
                                      }
                                      options={options3}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  <td class="p-1 select-container">
                                    <Select
                                      class="select-container"
                                      defaultValue={{
                                        label: l.add,
                                        value: l.add,
                                      }}
                                      id="clad4"
                                      onMenuOpen={onMenuOpen}
                                      className={"eyetestdetails clad3class"}
                                      classNamePrefix={
                                        "eyetestdetails clad3class"
                                      }
                                      options={options5}
                                      //onChange={handleTypeSelect}
                                      placeholder="0.00"
                                    />
                                  </td>
                                  {props.det.isContactLensType == false ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        id="clpd4"
                                        onMenuOpen={onMenuOpen}
                                        className={"eyetestdetails clpd3class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd3class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : null}
                                  {props.det.isContactLensType == true ? (
                                    <td class="p-1 select-container">
                                      <Select
                                        defaultValue={{
                                          label: l.pd,
                                          value: l.pd,
                                        }}
                                        onMenuOpen={onMenuOpen}
                                        id="clpd4"
                                        className={"eyetestdetails clpd3class"}
                                        classNamePrefix={
                                          "eyetestdetails clpd3class"
                                        }
                                        options={options4}
                                        //onChange={handleTypeSelect}
                                        placeholder="0.00"
                                      />
                                    </td>
                                  ) : (
                                    <td class="p-1 d-none" id="rpd">
                                      {l.pd}
                                    </td>
                                  )}
                                </>
                              ) : null}
                            </>
                          ))}
                        </tr>{" "}
                      </>
                    )}
                    <tr>
                      <td class="p-1">Hauteur pup*</td>
                      <td colSpan="5">
                        <input
                          type="text"
                          id="Heier4"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                      <td colSpan="5">
                        <input
                          id="Heiel4"
                          class="form-control flatpickr-basic"
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              )}
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de vision*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedevision4"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        // onChange={(e)=>setTypedevision4(e.value)}
                        className={"eyetestdetails typedevisionclass4"}
                        classNamePrefix={"eyetestdetails typedevisionclass4"}
                        options={Typedevision}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de verre*
                    </label>
                    <div class="select-container2">
                      <Select
                        class="form-control"
                        id="typedeverre4"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails typedeverreclass4"}
                        classNamePrefix={"eyetestdetails typedeverreclass4"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? TypedeverreSP3 : Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedTypedeverreA4}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Indice*
                    </label>
                    <div class="select-container1">
                      <Select
                        class="form-control"
                        id="indice"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails indiceclass4"}
                        classNamePrefix={"eyetestdetails indiceclass4"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? Indiceothers:Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedIndiceA4}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Couleur
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="color"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails frameCoclass4"}
                        classNamePrefix={"eyetestdetails frameCoclass4"}
                        options={selectedPanier == "A" ||
                          selectedPanier == "Mixte Verre A" ||
                          selectedPanier == "B" ||
                          selectedPanier == "Mixte Monture A"
                            || selectedPanier == "Sans Panier" ? colorA34:Nooption}
                        placeholder="Saisir une option"
                        onChange={setSelectedcolorA4}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Option de verre
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="lenstypedetails"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails lensTypeDclasss4"}
                        classNamePrefix={"eyetestdetails lensTypeDclass4"}
                        options={selectedPanier == "" ? "" :LensTypeDetailsB34}
                        placeholder="Saisir une option"
                        onChange={setSelectedLensTypeDetailsA4}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Traitement*
                    </label>
                    <div class="select-container3">
                      <Select
                        class="form-control"
                        id="treatment"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails treatmeclass4"}
                        classNamePrefix={"eyetestdetails treatmeclass4"}
                        options={selectedPanier == "" ? "" :TreatmentSP3}
                        placeholder="Saisir une option"
                        onChange={setSelectedTreatmentA4}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Type de monture*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="frameType4"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails infoFrameclass4"}
                        classNamePrefix={"eyetestdetails infoFrameclass4"}
                        options={selectedPanier == "" ? "" :frameTypeA1}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Monture*
                    </label>
                    <div class="select-container5">
                      <Select
                        class="form-control"
                        id="monture4"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails montureclass4"}
                        classNamePrefix={"eyetestdetails montureclass4"}
                        options={selectedPanier == "" ? "" :Monture34}
                        placeholder="Saisir une option"
                        onChange={setSelectedMontureA4}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label" for="login-email">
                      Fournisseur*
                    </label>
                    <div class="select-container4">
                      <Select
                        class="form-control"
                        id="supplier4"
                        defaultValue={{
                          label: state.orderLeftPD,
                          value: state.orderLeftPD,
                        }}
                        onMenuOpen={onMenuOpen}
                        className={"eyetestdetails supplierclass4"}
                        classNamePrefix={"eyetestdetails supplierclass4"}
                        options={Supplier}
                        placeholder="Saisir une option"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6  ">
                  <label>Prix</label>
                  <input
                    type="text"
                    id="price4"
                    class="form-control flatpickr-basic"
                    value={price4.toFixed(2) + " €"}
                    placeholder=""
                    disabled={isEdit== true}
                  />
                </div>
                <div class="col-lg-6  ">
                  <label>{window.$language.Commentsontheorder}</label>
                  <input
                    type="text"
                    id="com24"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-6 d-none">
                  <label>{window.$language.OtherComments}</label>
                  <input
                    type="text"
                    id="ocom24"
                    class="form-control flatpickr-basic"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-lg-4 mt-1">
                <div class="form-group ">
                  <label class="d-block ">Statut de la commande:</label>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio228"
                      name="status4"
                      value="Pending"
                      class="custom-control-input"
                      defaultChecked
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio228"
                    >
                      {window.$language.Pending}
                    </label>
                  </div>
                  <div class="custom-control custom-radio col">
                    <input
                      type="radio"
                      id="validationRadio238"
                      name="status4"
                      // value="ToBeOrdered"
                      value="SAV"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      for="validationRadio238"
                    >
                      SAV
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary data-submit mr-1 m-1"
              id="btnsubmit"
              onClick={() => submit()}
            >
              {window.$language.Submit}
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary m-1"
              id="close1243"
              data-dismiss="modal"
            >
              {window.$language.Cancel}
            </button>
            {isEdit == false ? <div class="col-lg-3 totalprice">
                  <label>Prix total de toutes les paires</label>
                  <input
                    type="text"
                    id="price4"
                    class="form-control flatpickr-basic"
                    value={totalprice.toFixed(2) + " €"}
                    placeholder=""
                  />
                </div>:null}
          </div>
        </form>
      </div>
    </div>
  );
  return (
    <>
      {isLoading ? <SymLoader1 /> : ""}
      {/* <input type="hidden"  id="hdnordernumber"></input> */}
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-2 mt-3">
            <section id="accordion-with-margin">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card collapse-icon">
                    <div class="card-body">
                      <div class="card-header border-bottom p-1 mb-1">
                        <div class="head-label d-flex align-items-center">
                          <div onClick={back} className="px-1">
                            <i
                              class="fa fa-arrow-left arrow_icon7"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h4 id="name1" class="mb-0">
                            {window.$language.PendingOrders}
                          </h4>
                        </div>
                        <div class="dt-action-buttons text-right">
                          <div class="dt-buttons d-inline-flex">
                            {state.utype == "ADMIN" ||
                            state.utype == "BACKOFFICE" ? (
                              <>
                                <button
                                  onClick={() => exportToExcel(props.val)}
                                  className="btn btn-primary"
                                  //ref={csvLink}
                                >
                                  Exporter des données (Excel)
                                </button>
                              </>
                            ) : null}
                            {state.utype == "ADMIN" ||
                            state.utype == "BACKOFFICE" ? (
                              <div class="btn-group mx-1">
                                <button
                                  type="reset"
                                  class="btn btn-outline-secondary float-right"
                                  id="assign"
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#modals-slide-in-1"
                                >
                                  {window.$language.ChangeStatus}
                                </button>
                              </div>
                            ) : null}
                            {state.utype == "BACKOFFICE" ||
                            state.utype == "ADMIN" ||
                            state.utype == "OPTICIAN" ? (
                              <>
                                {props.vv == 1 ? null : (
                                  <button
                                    onClick={() => add()}
                                    class="btn btn-primary mr-1"
                                    tabindex="0"
                                    aria-controls="DataTables_Table_0"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modals-slide-in"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-plus mr-50 font-small-4"
                                      >
                                        <line
                                          x1="12"
                                          y1="5"
                                          x2="12"
                                          y2="19"
                                        ></line>
                                        <line
                                          x1="5"
                                          y1="12"
                                          x2="19"
                                          y2="12"
                                        ></line>
                                      </svg>
                                      {window.$language.AddNewOrder}
                                    </span>
                                  </button>
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                        <div class="col-sm-2 col-md-2 pt-1">
                          <select
                            name="DataTables_Table_0_length"
                            onChange={(e) => {
                              setItemsPerPage(Number(e.target.value));
                              setIsLoading(true);
                            }}
                            aria-controls="DataTables_Table_0"
                            class="custom-select form-control"
                          >
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={500}>500</option>
                            <option value={50000}>Afficher tout</option>
                          </select>
                        </div>
                        {state.utype !== "EYEDOCTOR" &&
                        state.utype !== "PAITENT" ? (
                          <div class="col-sm-12 col-md-4 mr-0 pr-0">
                            <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setStartDate(event.target.value)
                                  }
                                  value={startDate}
                                />
                              </div>
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setEndDate(event.target.value)
                                  }
                                  value={endDate}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div class="col-sm-12 col-md-3  pt-1">
                          <div
                            id="DataTables_Table_0_filter"
                            class="dataTables_filter"
                          >
                            <label class="w-100">
                              <input
                                onChange={(data) =>
                                  getLocalEyeTest(data.target.value)
                                }
                                type="search"
                                class="form-control rounded-lg shadow-sm"
                                placeholder="Rechercher une information"
                                aria-controls="DataTables_Table_0"
                              />
                            </label>
                          </div>
                        </div>
                        {state.utype !== "EYEDOCTOR" &&
                        state.utype !== "PAITENT" ? (
                          <div class="col-sm-12 col-md-3 mr-0 pr-0  pt-1">
                            <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                              <div className="pr-1">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setIsLoading(true)}
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Rechercher..." : "Rechercher"}
                                </button>
                              </div>
                              <div className="col">
                                <button
                                  className="btn btn-outline-secondary"
                                  onClick={handleReset}
                                >
                                  Réinitialiser
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div id="ff" class="my-2 mx-1 border p-0">
                        <div class="container-fluid p-0">
                          <div class="col-md-12 px-0">
                            <div class="panel panel-default">
                              <div class="panel-body">
                                <div class="table-responsive">
                                  <table class="table table-condensed table-striped">
                                    <tbody class="table table-condensed table-striped">
                                      <tr>
                                        <td colspan="12" class="hiddenRow">
                                          <table class="table table-striped">
                                            <thead>
                                              <tr class="info">
                                                <th></th>
                                                <th>N° test</th>
                                                <th># commandes </th>
                                                {/* <th>
                                                  {window.$language.ORDERNO}
                                                </th> */}
                                                <th>Date</th>
                                                <th>
                                                  {window.$language.Optician}
                                                </th>
                                                <th>PDV</th>
                                                <th>Patient</th>
                                                <th>
                                                  {window.$language.STATUS}
                                                </th>
                                                <th>
                                                  {window.$language.ACTIONS}
                                                </th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {currentItems?.map((det) => (
                                                <tr>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      name="ordernumstat"
                                                      value={
                                                        det.orderCombinedId
                                                      }
                                                    />
                                                  </td>
                                                  {/* <td>{det.testUniqueCode}</td> */}
                                                  <td>{det.testUniqueCode}</td>
                                                  <td>{det.orderCount}</td>
                                                  <td>
                                                    {formatDate(
                                                      det.orderCreated
                                                    )}
                                                  </td>
                                                  <td>{det.opticianName}</td>
                                                  <td>{det.posName}</td>
                                                  <td>{det.patientName}</td>

                                                  <td>
                                                    <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                                      {det.status == "Annulé" ||
                                                      det.status == "Cancelled"
                                                        ? "Annulée"
                                                        : det.status ==
                                                            "Pending" ||
                                                          det.status ==
                                                            "En attente"
                                                        ? window.$language
                                                            .Pending
                                                        : det.status ==
                                                            "Ordered" ||
                                                          det.status ==
                                                            "Commandé"
                                                        ? "Commandée"
                                                        : det.status == "SAV"
                                                        ? "SAV"
                                                        : "À commander"}
                                                    </span>
                                                  </td>

                                                  <td>
                                                    <div
                                                      class="btn-group"
                                                      role="group"
                                                      aria-label="Basic example"
                                                    >
                                                      <button
                                                        type="button"
                                                        class="btn btn-outline-secondary"
                                                        onClick={() =>
                                                          Orderdetails(
                                                            det.orderModelList
                                                          )
                                                        }
                                                        href="javascript:void(0);"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="View Details"
                                                      >
                                                        <i class="far fa-eye fa-fw"></i>
                                                      </button>
                                                      {(state.utype ==
                                                        "OPTICIAN" ||
                                                        state.utype ==
                                                          "ADMIN" ||
                                                        state.utype ==
                                                          "BACKOFFICE") &&
                                                      det.status == "Pending" &&
                                                      det.status !==
                                                        "ToBeOrdered" ? (
                                                        <button
                                                          type="button"
                                                          class="btn btn-outline-secondary"
                                                          data-toggle="modal"
                                                          data-target="#modals-slide-in"
                                                          onClick={() =>
                                                            edit(
                                                              det.orderModelList
                                                            )
                                                          }
                                                          href="javascript:void(0);"
                                                          data-placement="top"
                                                          title="Edit"
                                                        >
                                                          <i class="far fa-edit fa-fw"></i>
                                                        </button>
                                                      ) : null}
                                                      {(state.utype ==
                                                        "ADMIN" ||
                                                        state.utype ==
                                                          "BACKOFFICE") &&
                                                      det.status ==
                                                        "ToBeOrdered" &&
                                                      det.status !==
                                                        "Pending" ? (
                                                        <button
                                                          type="button"
                                                          class="btn btn-outline-secondary"
                                                          data-toggle="modal"
                                                          data-target="#modals-slide-in"
                                                          onClick={() =>
                                                            edit(
                                                              det.orderModelList
                                                            )
                                                          }
                                                          href="javascript:void(0);"
                                                          data-placement="top"
                                                          title="Edit"
                                                        >
                                                          <i class="far fa-edit fa-fw"></i>
                                                        </button>
                                                      ) : null}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          <ReactPaginate
                                            nextLabel="suivant>"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< retour"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {from1}

                      <div
                        class="modal modal-slide-in fade"
                        id="modals-slide-in-1"
                      >
                        <div class="modal-dialog sidebar-sm">
                          <form class="add-new-record modal-content pt-0">
                            <button
                              type="button"
                              class="close"
                              id="www4"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              ×
                            </button>
                            <div class="modal-header mb-1">
                              <h5 class="modal-title" id="exampleModalLabel">
                                {window.$language.ChangeStatus}
                              </h5>
                            </div>
                            <div class="modal-body flex-grow-1">
                              <div>
                                <form class="dt_adv_search" method="POST">
                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label for="languages">
                                        {window.$language.ChangeStatus}
                                      </label>
                                      <select
                                        id="orderstat"
                                        class="form-control"
                                      >
                                        <option value="Pending">
                                          {window.$language.Pending}
                                        </option>
                                        <option value="SAV">SAV</option>
                                        <option value="ToBeOrdered">
                                          À commander
                                        </option>

                                        <option value="Commandé">
                                          Commandée
                                        </option>
                                        <option value="Annulé">Annulée</option>
                                      </select>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary data-submit mr-1 m-1"
                                onClick={() => notify()}
                              >
                                {window.$language.ChangeStatus}
                              </button>
                              <button
                                type="reset"
                                class="btn btn-outline-secondary m-1"
                                id="close1122"
                                data-dismiss="modal"
                              >
                                {window.$language.Cancel}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default orderprocessing;
