import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { API_URL } from "../../config";
import swal from "sweetalert";

class UserEmailUpdate extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      emailreq: [],
      count: 1,
      utype: localStorage.getItem("type"),
    };
  }
  approve(patientuniquecode,newemail,status) {
    swal({
      title: "Êtes vous sur ?",
      text: "En cliquant sur “ok”, vous acceptez la demande de modification d’email.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/ApproveRequestForNewMail";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            patientuniquecode: patientuniquecode,
            newemail:newemail,
            status: status
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Merci !",
                text: "L’adresse email a été modifiée avec succès.",
                icon: "success",
              });
              this.componentDidMount();
            } else {
              swal({
                title: "OOPS!",
                text: "Something went wong",
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: "Something went wong",
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  submit() 
  {
    /* Validations */
    var res= false;
    $("#inputform")
      .find(".form-group, .form-row")
      .find(".form-control")
      .each(function () 
      {
        if ($(this).val() == "") 
        {
          swal({
            title: "OOPS!",
            text: $(this).parent().find("label").html() + " can not be blank!",
            icon: "warning",
          });
         res=true; 
         return false;  
        }
        
      });
      const patucValue = $("#patuc").val();
      if (patucValue.length > 6) 
      {
        $('#error-message').text('Character limit exceeded (maximum 5 characters)').css('color', 'red');
        res =true
        return false; // Prevent form submission
      }
    let data1 = {
        patientuniquecode: document.getElementById("patuc").value,
        email: document.getElementById("oldem").value,
        newemail: document.getElementById("newem").value,
        requestfrom: localStorage.getItem("opmail"),
        requestfromrole: "OPTICIAN",
    };


      if(res==false)
    {
 /////////////////
 let logourl = API_URL + "/RequestForNewMail";
 let msg = "";
 if (this.state.count == 1) {
   logourl = API_URL + "/RequestForNewMail";
   msg = "Votre demande a bien été enregistrée et sera prise en charge par nos équipes au plus vite.";
 } 
 /////////////////////
 // let logourl = API_URL + "/Products/add";
 const requestOptions = {
   method: "POST",
   headers: {
     "Content-Type": "application/json",
     //authorization: localStorage.getItem("token"),
   },
   body: JSON.stringify(data1),
 };
 fetch(logourl, requestOptions)
   .then((res) => res.json())
   .then((json) => {
     if (json == "NewEmailAlreadyExist") {

       swal({
         title: "Oops!",
         text: "La nouvelle adresse e-mail est déjà enregistrée avec un autre compte patient. Chaque e-mail peut seulement être utilisé une fois.",
         icon: "warning",
       });
       document.getElementById("close").click();
       this.componentDidMount();
       return;
     }
     //this.setState({usrdet:json})
     // alert(json)
     swal({
       title: "Done!",
       text: msg,
       icon: "success",
     });
     document.getElementById("close").click();
     this.componentDidMount();
   })
   .catch((e) => {
     swal({
       title: "OOPS!",
       text: "La nouvelle adresse e-mail est déjà enregistrée avec un autre compte patient. Chaque e-mail peut seulement être utilisé une fois.",
       icon: "warning",
     });

     console.log(e);
   });
  }
   
    //////
  }

  
  componentDidMount() {
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    let logourl = API_URL + "/RequestedNewMailList";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ emailreq: json });
      });
  }

  addnew() {
    $('#error-message').text("");
    document.getElementById("patuc").value = "";
    document.getElementById("oldem").value = "";
    document.getElementById("newem").value = "";
    //document.getElementById("tcode").removeAttribute("readonly")
  }
  edit(usr) {
    this.setState({
      count: 2,
    });
    this.reset();
    document.getElementById("patuc").value = usr.patientuniquecode;
    document.getElementById("oldem").value = usr.email;
    document.getElementById("newem").value = usr.newemail;
    $('#error-message').text("");
  }
  reset() {
    document.getElementById("patuc").value = "";
    document.getElementById("oldem").value = "";
    document.getElementById("newem").value = "";
   
  }
  render() {
    return (
      <>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section id="basic-datatable">
              <div class="row">
                <div class="col">
                  <div class="card px-2 pb-4">
                    <div class="card-header border-bottom mr-1">
                      <div class="head-label">
                        <h4 class="mb-0">Demande de modification adresse email patient</h4>
                      </div>
                      <div class="dt-action-buttons text-right">
                        <div class="dt-buttons d-inline-flex">
                          <button
                            class="btn btn-primary mr-1"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            type="button"
                            data-toggle="modal"
                            data-target="#modals-slide-in"
                            onClick={this.addnew}
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus mr-50 font-small-4"
                              >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </svg>
                              Nouvelle modification
                            </span>
                          </button>{" "}
                        </div>
                      </div>
                    </div>

                    <hr class="my-0" />
                    <div class="d-flex justify-content-between align-items-center mx-0 row"></div>

                    <table class="datatables-basic table mt-1 shadow  table-hover">
                   
                    {this.state.utype == "ADMIN" || this.state.utype == "BACKOFFICE" || this.state.utype == "OPTICIAN" ? (
                        <>
                      <thead>
                        <tr>
                          <th>Code patient</th>
                          <th>Adresse mail erronée</th>
                          <th>Adresse mail correcte</th>
                          <th>DATE</th>
                          <th>STATUS</th>
                          {this.state.utype !=="OPTICIAN"?
                          <th>{window.$language.Actions}</th> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.emailreq.map((usr) => (
                          <tr>
                            {/* <td>{usr.requesterName}</td> */}
                            <td>{usr.patientUniqueCode}</td>
                            <td>{usr.oldEmail}</td>
                            <td>{usr.newEmail}</td>
                            <td>{usr.submittedDate}</td>
                            <td>{usr.statusEmail=="Approve"?"Approuvé":"En cours de traitement"}</td>
                            
                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >{this.state.utype !=="OPTICIAN" && usr.statusEmail !=="Approve" ?
                              <>
                                <button
                                  type="button"
                                  onClick={() => this.approve(usr.patientUniqueCode,usr.newEmail,usr.status="Approve")}
                                  class="btn btn-outline-success"
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Approve"
                                >
                                  Approuver
                                </button>
                                <button
                                type="button"
                                onClick={() => this.approve(usr.patientUniqueCode,usr.newEmail,usr.status="Decline")}
                                class="p-1 btn btn-outline-danger"
                                href="javascript:void(0);"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Decline"
                              >
                               Refuser
                              </button>
                              </>
                                : null}
                              </div>
                            </td> 
                          </tr>
                        ))}
                      </tbody>
                      </>) : null}
                    </table>
                  </div>
                </div>
              </div>

              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm w-25">
                  <form class="add-new-record modal-content pt-0" id="inputform">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                      Nouvelle modification
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1">
                      <div class="form-row mb-1">
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            Code patient unique
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="patuc"
                            aria-label=""
                            placeholder="patient unique Code"
                          />
                          <div id="error-message" class="error-message"></div>
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                           Adresse mail patient erronée
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="oldem"
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                           Adresse mail patient correcte
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="newem"
                            aria-label=""
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1"
                        onClick={() => this.submit()}
                      >
                        {window.$language.Submit}
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                      >
                        {window.$language.Cancel}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
export default UserEmailUpdate;
