import React, { Component } from "react";
import ReactDOM from "react-dom";
import Product from "./Product";
import { API_URL } from "../../config";
class Productdetais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      utype: localStorage.getItem("type"),
    };

    console.log(this.props);
  }
  submit() {}
  Product() {
    console.log("hi");
    ReactDOM.render(<Product />, document.getElementById("c11"));
  }

  render() {
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <section class="app-user-edit col-9 pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="media mb-2">
                            <img
                              src={
                                API_URL + "/" + this.props.prd.profileImagePath
                              }
                              alt="users avatar"
                              class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                              height="90"
                              width="90"
                            />
                            <div class="media-body mt-50">
                              <h4>{this.props.prd.productname}</h4>
                              <div class="col-12 d-flex mt-1 px-0">
                                <label
                                  class="btn btn-primary mr-75 mb-0"
                                  for="change-picture"
                                >
                                  <span class="d-none d-sm-block">
                                    {window.$language.Change}
                                  </span>
                                  <input
                                    class="form-control"
                                    type="file"
                                    id="change-picture"
                                    hidden
                                    accept="image/png, image/jpeg, image/jpg"
                                  />
                                  <span class="d-block d-sm-none">
                                    <i class="mr-0" data-feather="edit"></i>
                                  </span>
                                </label>
                                <button class="btn btn-outline-secondary d-none d-sm-block">
                                  {window.$language.Remove}
                                </button>
                                <button class="btn btn-outline-secondary d-block d-sm-none">
                                  <i class="mr-0" data-feather="trash-2"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">
                                    {window.$language.PRODUCTNAME}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.productname}
                                    class="form-control"
                                    name="username"
                                    id="username"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.PRODUCTTYPE}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.productType}
                                    class="form-control"
                                    name="name"
                                    id="name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.ProductSubType}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.productSubType}
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.CorrectionRange}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.props.prd.correctionRange
                                    }
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.BlueFilter}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.blueFilter}
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.TintType}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.tintType}
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.TreatmentType}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.prd.treatmentType}
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.Product}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Productdetais;
