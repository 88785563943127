import React, { Component } from "react";
import ReactDOM from "react-dom";
import Eyeresult from "../../EyetestComponent/Eyetestresult";
import Patientreg from "./pacientreg";
import { API_URL } from "../../../config";

class paidetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      utype: localStorage.getItem("type"),
    };

    console.log(this.props.usr);
  }
  submit() {}
  Eyeresult() {
    console.log("hi");
    ReactDOM.render(
      <Eyeresult patientemail={this.props.usr.email} />,
      document.getElementById("c11")
    );
  }
  patientreg() {
    console.log("hi");
    ReactDOM.render(<Patientreg />, document.getElementById("c11"));
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthAbbreviation = months[monthIndex - 1];

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  render() {
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <section class="app-user-edit col-9 pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="media mb-2">
                            <img
                              src={
                                this.props.usr.profileImagePath == ""
                                  ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                  : API_URL +
                                    "/" +
                                    this.props.usr.profileImagePath
                              }
                              alt="users avatar"
                              class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                              height="90"
                              width="90"
                            />
                            <div class="media-body mt-50">
                              <h4>
                                {this.props.usr.name +
                                  "  " +
                                  this.props.usr.lastName}
                              </h4>
                            </div>
                          </div>

                          <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">PATIENT UNIQUE CODE</label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    defaultValue={
                                      this.props.usr.patientUniqueCode
                                    }
                                    readOnly
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">
                                    {window.$language.FirstName}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    defaultValue={this.props.usr.name}
                                    readOnly
                                    name="username"
                                    id="username"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.LastName}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    defaultValue={this.props.usr.lastName}
                                    readOnly
                                    name="name"
                                    id="name"
                                  />
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.Email}
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    defaultValue={this.props.usr.email}
                                    readOnly
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="status">
                                    {window.$language.Doyouwearglasses}?
                                  </label>
                                  <select
                                    defaultValue={this.props.usr.email}
                                    readOnly
                                    class="form-control"
                                  >
                                    <option>{window.$language.Yes}</option>
                                    <option>{window.$language.No}</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                            </div>
                          </form>
                          <form class="form-validate">
                            <div class="row mt-1">
                              <div class="col-12">
                                <h4 class="mb-1">
                                  <i
                                    data-feather="user"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    {window.$language.PersonalInformation}
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.UserType}
                                  </label>
                                  <select id="languages" class="form-control">
                                    <option value="Patient" readOnly selected>
                                      Patient
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="city">
                                    {window.$language.Gender}
                                  </label>
                                  <input
                                    id="city"
                                    type="text"
                                    class="form-control"
                                    defaultValue={this.props.usr.gender}
                                    readOnly
                                    name="city"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="fp-default">
                                    {window.$language.DateofBirth}
                                  </label>
                                  <input
                                    id="fp-default"
                                    type="text"
                                    defaultValue={this.formatDate(this.props.usr.dateOfBirth)}
                                    readOnly
                                    class="form-control birthdate-picker"
                                    name="dob"
                                    placeholder="01 Jan 2000"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="mobile">
                                    {window.$language.Phone}
                                  </label>
                                  <input
                                    id="mobile"
                                    defaultValue={this.props.usr.mobile}
                                    readOnly
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                  />
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.Languages}
                                  </label>
                                  <select
                                    id="languages"
                                    class="form-control"
                                    readOnly
                                  >
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French" selected>
                                      French
                                    </option>
                                    <option value="Russian">Russian</option>
                                    <option value="German">German</option>
                                    <option value="Arabic">Arabic</option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>
                                </div>
                              </div>
                              {/* <div class="col-lg-4 col-md-6">
                                {/* <div class="form-group">
                                                    <label class="d-block mb-1">Gender</label>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="male" name="gender" class="custom-control-input" />
                                                        <label class="custom-control-label" for="male">Male</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="female" name="gender" class="custom-control-input" checked />
                                                        <label class="custom-control-label" for="female">Female</label>
                                                    </div>
                                                </div>
                              </div> */}

                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="doctorAddressOfTheHealthCenter">
                                    {window.$language.AdditionalComments}
                                  </label>
                                  <input
                                    id="doctorAddressOfTheHealthCenter"
                                    defaultValue={
                                      this.props.usr.additionalComments
                                    }
                                    readOnly
                                    type="text"
                                    class="form-control"
                                    name="address"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="doctorAddressOfTheHealthCenter">
                                    {window.$language.Address}
                                  </label>
                                  <input
                                    id="doctorAddressOfTheHealthCenter"
                                    defaultValue={
                                      this.props.usr
                                        .doctorAddressOfTheHealthCenter}
                                    readOnly
                                    type="text"
                                    class="form-control"
                                    name="address"
                                  />
                                </div>
                              </div>
                               {this.state.utype == "BACKOFFICE" ||this.state.utype == "ADMIN" ? (
                                <>
                                  <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                      <label for="postcode">
                                        {window.$language.Optician}
                                      </label>
                                      <input
                                        id="postcode"
                                        defaultValue={
                                          this.props.usr.thirdPartyOpticianName
                                        }
                                        type="text"
                                        class="form-control"
                                        readOnly
                                        placeholder=""
                                        name="zip"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                      <label for="city">
                                        {window.$language.POSNAME}
                                      </label>
                                      <input
                                        id="city"
                                        defaultValue={
                                          this.props.usr.thirdPartyOptPOSName
                                        }
                                        type="text"
                                        class="form-control"
                                        readOnly
                                        name="city"
                                      />
                                    </div>
                                  </div>{" "}
                                </>
                              ) : null}
                              {/*     <div class="col-lg-4 col-md-6">
                                                <div class="form-group">
                                                    <label for="state">State</label>
                                                    <input id="state" type="text" class="form-control" name="state" placeholder="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="form-group">
                                                    <label for="country">Country</label>
                                                    <input id="country" type="text" class="form-control" name="country" placeholder="" />
                                                </div>
                                            </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                      <h4>{window.$language.ACTIONS}</h4>
                      {this.state.utype == "OPTICIAN" ||
                      this.state.utype == "POINTOFSALES" ||
                      this.state.utype == "BACKOFFICE" ||
                      this.state.utype == "ADMIN" || this.state.utype == "EYEDOCTOR"? (
                        <>
                          <button
                            class="btn btn-primary btn-block mb-75"
                            data-toggle=""
                            data-target=""
                            onClick={() => this.Eyeresult()}
                          >
                            <i class="far fa-eye mr-50"></i>
                            <span>Test de vue</span>
                          </button>
                        </>
                      ) : null}

                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.patientreg}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default paidetails;
